<template>
    <div>
        <h5 class="data-table__headline">Sichtbare Spalten</h5>
        <div class="checkbox " v-for="(label, key) in items">
            <label class="data-table__item" :for="key">
                <input
                    type="checkbox"
                    :id="key"
                    :value="key"
                    v-model="selectedKeys"
                    @change="onChange"
                >
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script>
import { difference, keys } from 'ramda'
import storage from '@/util/storage'
import EventBus from '@/util/EventBus'

const STORAGE_KEY = `${window.location.pathname}_selectedKeys`

export default {
    data () {
        return {
            items: {},
            selectedKeys: [],
        }
    },

    watch: {
        selectedKeys (newSelectedKeys) {
            storage.set(STORAGE_KEY, newSelectedKeys)
        },
    },

    created () {
        this.items = window.initialData.filter
        this.selectedKeys = storage.getArray(STORAGE_KEY, keys(this.items))

        setTimeout(() => {
            EventBus.$emit('selectFilters', this.unselectedKeys)
        }, 100)
        EventBus.$on('requestSelectedFilters', (hiddenFields) => {
            EventBus.$emit('selectFilters', this.unselectedKeys)
        })
    },

    computed: {
        unselectedKeys () {
            return difference(keys(this.items), this.selectedKeys)
        },
    },

    methods: {
        onChange (key, event) {
            EventBus.$emit('selectFilters', this.unselectedKeys)
        },
    },
}
</script>
