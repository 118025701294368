import { curry, equals } from 'ramda'


/**
 * Attach a change event to the element, where the old and new value are
 * passed to the callback. Returns a function to remove the listener.
 *
 * @sig jQuery Elem jQ => (jQ -> a) -> jQ -> (Obj -> *) -> (* -> undefined)
 */
export const onChange = curry((getValue, $elem, callback) => {
    let _currentValue = getValue($elem)

    // passUpdate :: Dom Event -> undefined
    const passUpdate = (event) => {
        const newValue = getValue($elem)
        if (equals(newValue, _currentValue)) {
            return
        }

        callback({ event, oldValue: _currentValue, newValue, $elem })
        _currentValue = newValue
    }

    $elem.on('change', passUpdate)

    return () => {
        $elem.off('change', passUpdate)
    }
})
