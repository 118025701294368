const $ = window.$

// When modifying the constant below, make sure to update it in Zusatzinformation.php as well.
const ACCREDITED_OTHER_REASON = 'Other reason'
// When modifying the constant below, make sure to update it in KursAdmin.php as well.
const CRITERIA_TABLE_NAME = 'Kriterienabgleich des DAAD'
let calculateCountError = false

const updateDataBySelect = (inputField, outputId, criteriaSelectValue) => {
    const output = $(`input[id$=${outputId}]`)
    const successfulElement = $('<span class="criteria-check-show badge badge-success badge--circular u-mb--tiny"><i class="mdi mdi-check c--white"></i></span>')
    const unsuccessfulElement = $('<span class="criteria-check-show badge bg-red badge--circular u-mb--tiny"><i class="mdi mdi-exclamation c--white"></i></span>')
    let isChecked = false
    if (inputField.prop('type') === 'checkbox') {
        isChecked = inputField.is(':checked')
    } else {
        isChecked = inputField.val() === '1'
    }

    if (inputField.attr('id').indexOf('courseIsAccredited') !== -1) {
        if (ACCREDITED_OTHER_REASON === inputField.val()) {
            isChecked = false
        } else {
            isChecked = true
        }
    }

    const newInputAttributes = {
        type: 'text',
        disabled: true,
        id: output.attr('id'),
        class: 'is-hidden',
    }

    if (undefined !== criteriaSelectValue) {
        Object.entries(criteriaSelectValue).forEach(([key, value]) => {
            if (typeof value !== 'object') {
                isChecked = Object.values(criteriaSelectValue).includes(inputField.val())
            } else if (!isChecked) {
                const dependentSelectField = $(`select[name*=${key}], input[name*=${key}]`)
                if (dependentSelectField.length > 1) {
                    $.each(dependentSelectField, function (index, radioValue) {
                        if ($(radioValue).val() === '1' && $(radioValue).is(':checked')) {
                            isChecked = Object.values(value).includes(inputField.val())
                        }

                        $(radioValue).on('change', function () {
                            const output = $(`input[id$=${outputId}]`)
                            const isRadioValueTrue = $(radioValue).val() === '1'

                            const isChecked = isRadioValueTrue
                                ? Object.values(value).includes(inputField.val()) || Object.values(criteriaSelectValue).includes(inputField.val())
                                : Object.values(criteriaSelectValue).includes(inputField.val())

                            output.parent().children('.criteria-check-show').remove()
                            const targetElement = isChecked ? successfulElement : unsuccessfulElement
                            output.parent().prepend(targetElement)
                            output.replaceWith($('<input>').attr(newInputAttributes))
                        })
                    })
                } else {
                    if (dependentSelectField.val() === '1') {
                        isChecked = Object.values(value).includes(inputField.val())
                    }

                    dependentSelectField.on('change', function () {
                        const output = $(`input[id$=${outputId}]`)
                        const isDependentSelectTrue = dependentSelectField.val() === '1'

                        const isChecked = isDependentSelectTrue
                            ? Object.values(value).includes(inputField.val()) || Object.values(criteriaSelectValue).includes(inputField.val())
                            : Object.values(criteriaSelectValue).includes(inputField.val())

                        output.parent().children('.criteria-check-show').remove()
                        const targetElement = isChecked ? successfulElement : unsuccessfulElement
                        output.parent().prepend(targetElement)
                        output.replaceWith($('<input>').attr(newInputAttributes))
                    })
                }
            }
        })
    }

    output.parent().children('.criteria-check-show').remove()
    const targetElement = isChecked ? successfulElement : unsuccessfulElement
    output.parent().prepend(targetElement)
    output.replaceWith($('<input>').attr(newInputAttributes))
}

const updateDataBySelectMultiple = (inputField, mapFiedls, outputId, criteriaValue) => {
    const successfulElement = $('<span class="criteria-check-show badge badge-success badge--circular u-mb--tiny"><i class="mdi mdi-check c--white"></i></span>')
    const unsuccessfulElement = $('<span class="criteria-check-show badge bg-red badge--circular u-mb--tiny"><i class="mdi mdi-exclamation c--white"></i></span>')

    const output = $(`input[id$=${outputId}]`)
    const newInputAttributes = {
        type: 'text',
        disabled: true,
        id: output.attr('id'),
        class: 'is-hidden',
    }

    if (inputField.val() === null) {
        output.parent().children('.criteria-check-show').remove()
        output.parent().prepend(unsuccessfulElement)
        output.replaceWith($('<input>').attr(newInputAttributes))

        return false
    }

    const intersection = inputField.val().filter(component => mapFiedls.includes(component))

    output.parent().children('.criteria-check-show').remove()
    const targetElement = intersection.length >= criteriaValue ? successfulElement : unsuccessfulElement
    output.parent().prepend(targetElement)
    output.replaceWith($('<input>').attr(newInputAttributes))
}

const updateDataByInput = (inputField, outputId, criteriaInputValue) => {
    const successfulElement = $('<span class="criteria-check-show badge badge-success badge--circular u-mb--tiny"><i class="mdi mdi-check c--white"></i></span>')
    const unsuccessfulElement = $('<span class="criteria-check-show badge bg-red badge--circular u-mb--tiny"><i class="mdi mdi-exclamation c--white"></i></span>')
    const output = $(`input[id$=${outputId}]`)

    output.parent().children('.criteria-check-show').remove()
    const targetElement = inputField.val() !== '' && inputField.val() <= criteriaInputValue ? successfulElement : unsuccessfulElement
    output.parent().prepend(targetElement)
    output.replaceWith($('<input>').attr({
        type: 'text',
        value: inputField.val(),
        disabled: true,
        id: output.attr('id'),
        style: 'width: 32px;',
        class: 'u-mr--small',
    }))
}

const initializeSelectListener = (inputField, outputId, criteriaSelectValue) => {
    let radioTrigger = false // Important for the initial setup when radio buttons are empty
    if (inputField.length > 1) {
        $.each(inputField, function (index, field) {
            if (!radioTrigger) {
                updateDataBySelect($(field), outputId, criteriaSelectValue)
            }
            if ($(field).is(':checked')) {
                radioTrigger = true
                updateDataBySelect($(field), outputId, criteriaSelectValue)
            }
            $(field).on('change', function () {
                updateDataBySelect($(field), outputId, criteriaSelectValue)
            })
        })
    } else {
        updateDataBySelect(inputField, outputId, criteriaSelectValue)
        inputField.on('change', function () {
            updateDataBySelect(inputField, outputId, criteriaSelectValue)
        })
    }
}

const initializeSelectMultipleListener = (inputField, mapFiedls, outputId, criteriaValue) => {
    updateDataBySelectMultiple(inputField, mapFiedls, outputId, criteriaValue)
    inputField.on('change', function () {
        updateDataBySelectMultiple(inputField, mapFiedls, outputId, criteriaValue)
    })
}

const initializeInputListener = (inputField, outputId, criteriaInputValue) => {
    updateDataByInput(inputField, outputId, criteriaInputValue)
    inputField.on('input', function () {
        updateDataByInput(inputField, outputId, criteriaInputValue)
    })
}

const checkCriteria = (elem) => {
    if (undefined === elem || undefined === elem.data('checked-fields')) {
        return
    }

    $.each(elem.data('checked-fields'), function (index, value) {
        const origIndex = index
        if (index.includes('_addSuffix_')) {
            index = index.split('_addSuffix')[0]
        }
        const inputField = $(`select[name*="[${index}]"], input[name*="[${index}]`)
        const inputCriteriaField = elem.find(`span:contains(${value})`).prev('input')

        if (inputField.prop('type') === 'select-one' || inputField.prop('type') === 'checkbox' || inputField.prop('type') === 'radio') {
            initializeSelectListener(inputField, inputCriteriaField.attr('id'), elem.data('criteria-select-value')[index])
        } else if (inputField.prop('type') === 'text') {
            initializeInputListener(inputField, inputCriteriaField.attr('id'), elem.data('criteria-input-value')[index])
        } else if (inputField.prop('type') === 'select-multiple' && elem.data('map-multiselect')[origIndex] !== undefined &&
            elem.data('multiselect-count')[index] !== undefined) {
            initializeSelectMultipleListener(inputField, elem.data('map-multiselect')[origIndex], inputCriteriaField.attr('id'), elem.data('multiselect-count')[index])
        }
    })
}

const initializeCountErrorListener = (elem) => {
    calculateCountErrors(elem)
    elem.on('change', function () {
        calculateCountErrors(elem)
    })
}

const calculateCountErrors = (elem) => {
    const criteriaCheckServices = $('div[id$="zusatzinformation_criteriaCheckServices"]')
    const links = $(`a:contains(${CRITERIA_TABLE_NAME})`).find('span:nth-child(2)')

    setTimeout(() => {
        const hasError = criteriaCheckServices.hasClass('has-error')
        const isHidden = criteriaCheckServices.css('display') === 'none'
        const operation = hasError && isHidden ? -1 : hasError && calculateCountError ? 1 : 0

        links.each(function () {
            const span = $(this)
            const intValue = parseInt(span.text(), 10)
            if (!isNaN(intValue)) {
                span.text(intValue + operation)
            }
        })

        if (operation !== 0) {
            calculateCountError = !calculateCountError
        }
    }, 500)
}

const criteria = {
    criteriaCheck: $('ul[id$="zusatzinformation_criteriaCheck"]'),
    criteriaCheckServices: $('ul[id$="zusatzinformation_criteriaCheckServices"]'),
    studyModes: $('select[id$="kursdaten_studyModes"]'),

    init () {
        checkCriteria(this.criteriaCheck)
        checkCriteria(this.criteriaCheckServices)
        initializeCountErrorListener(this.studyModes)
    },
}
export default criteria
