const $ = window.$

const $confirmModal = $(document.getElementById('js-confirm-modal'))
const $confirmButton = $confirmModal.find('.js-submit')
const $cancelButton = $confirmModal.find('.js-cancel')
const $confirmModalTitle = $confirmModal.find('.modal-title')
const $confirmModalHeader = $confirmModal.find('.modal-header')
const $confirmModalBody = $confirmModal.find('.modal-body .js-confirm-modal-body')
const $confirmModalFooter = $confirmModal.find('.modal-footer')
const $confirmModalFooterReverseFocus = $confirmModal.find('.modal-footer-reverse-focus')
// this event is global. no need to cancel this listener
$cancelButton.on('click', () => {
    $confirmModal.modal('hide')
})

/**
 * For a jquery Element, return its data-attributes for the confirmation modal
 *
 * data-msg, data-submit, data-cancel, data-title
 *
 * @sig jQuery Element -> Obj
 * @see onConfirm
 */
export const getConfirmOptions = ($elem) => {
    const confirmOptions = { title: '' } // is empty by default
    const addPropToOptions = (propName) => {
        if ($elem.attr(`data-${propName}`)) {
            confirmOptions[propName] = $elem.data(propName)
        }
    }

    ['msg', 'submit', 'cancel', 'title', 'footerreversefocus'].forEach(addPropToOptions)

    return confirmOptions
}

/**
 * Custom Confirm Dialog. Use this instead of var bla = confirm(...) if (bla)...
 *
 * @param options  Specify confirm message (msg), title, submit button label
 *   (submit) and cancel button label (cancle)
 * @param cb        A callback to be executed if users confirms
 */
export function onConfirm ({ msg, title = 'Bestätigen', submit = 'Bestätigen', cancel = 'Abbrechen', footerreversefocus = false }, cb) {
    $confirmModalFooterReverseFocus.hide()
    $confirmModalBody.html(msg)
    $confirmModalTitle.text(title)
    $confirmButton.text(submit)
    $cancelButton.text(cancel)
    $confirmModal.modal().show()

    if (footerreversefocus) {
        $confirmModalBody.parent().removeClass('text-center')
        $confirmModalFooterReverseFocus.find('.o-mixed__right').addClass('o-mixed--width-50')
        $confirmModalFooter.hide()
        $confirmModalFooterReverseFocus.show()
    }

    if (title === '') {
        $confirmModalHeader.addClass('hidden')
    }

    // local event handler, will be removed when modal is closed
    $confirmButton.on('click', () => {
        $confirmModal.modal('hide')
        cb()
    })

    $confirmModal.on('hidden.bs.modal', () => {
        $confirmModalHeader.removeClass('hidden')
        $confirmButton.off('click')
        // remove the event listeners when the dialog is dismissed
    })
}

/**
 * Custom Confirm Dialog. Use this instead of var bla = confirm(...) if (bla)...
 *
 * @param options  Specify confirm message (msg), title, submit button label
 *   (submit) and cancel button label (submit)
 * @param cb        A callback to be executed if users confirms
 */
export function onTwoButtonConfirm ({ msg, title = 'Bestätigen', submit = 'Bestätigen', cancel = 'Abbrechen' }, cb) {
    $confirmModalBody.html(msg)
    $confirmModalTitle.html(title)
    $confirmButton.text(submit)
    $cancelButton.text(cancel)
    $confirmModal.modal().show()

    const btnClose = $confirmModal.find('button.close')
    btnClose.css('display', 'block')
    $confirmModalHeader.css('padding-bottom', 0)
    $confirmModalHeader.parent().css('width', 600)
    $confirmModalBody.parent().css('padding-top', 0)
    $confirmModalBody.find('ul').addClass('text-left')

    // local event handler, will be removed when modal is closed
    $confirmButton.on('click', () => {
        $confirmModal.modal('hide')
        cb('toDefault')
    })

    // local event handler, will be removed when modal is closed
    $cancelButton.on('click', () => {
        cb('toHelp')
    })

    $confirmModal.on('hidden.bs.modal', () => {
        $confirmModalHeader.removeClass('hidden')
        $confirmButton.off('click')
        $cancelButton.off('click')
        // remove the event listeners when the dialog is dismissed
    })
}

/**
 * Custom Confirm Dialog. Use this instead of var bla = confirm(...) if (bla)...
 *
 * @param options  Specify confirm message (msg), title, submit button label
 *   (submit) and cancel button label (cancel)
 * @param confirmFunc        A callback to be executed if users confirms
 * @param cancelFunc        A callback to be executed if users cancel
 */
export function onConfirmOrCancel ({ msg, title = 'Bestätigen', submit = 'Bestätigen', cancel = 'Abbrechen', footerreversefocus = false }, confirmFunc, cancelFunc) {
    $confirmModalFooterReverseFocus.hide()
    $confirmModalBody.html(msg)
    $confirmModalTitle.text(title)
    $confirmButton.text(submit)
    $cancelButton.text(cancel)
    $confirmModal.modal().show()
    let modalTrigger = false

    if (footerreversefocus) {
        $confirmModalFooter.hide()
        $confirmModalFooterReverseFocus.show()
    }

    if (title === '') {
        $confirmModalHeader.addClass('hidden')
    }

    // local event handler, will be removed when modal is closed
    $confirmButton.on('click', () => {
        $confirmModal.modal('hide')
        confirmFunc()
        modalTrigger = true
    })

    // local event handler, will be removed when modal is closed
    $cancelButton.on('click', () => {
        $confirmModal.modal('hide')
        cancelFunc()
    })

    $confirmModal.on('hidden.bs.modal', () => {
        $confirmModalHeader.removeClass('hidden')
        if (!modalTrigger) {
            cancelFunc()
        }
        $confirmButton.off('click')
        $cancelButton.off('click')
        $confirmModal.off()
        // remove the event listeners when the dialog is dismissed
    })
}

/**
 * Click and confirm listener.
 *
 * @param elem      The DOM elem (not jquery). e.g. from document.querySelector
 * @param options   The config for the confirm modal (see onConfirm)
 * @param cb        A callback to be executed if users confirms
 */
export function onClickAndConfirm (elem, options, cb) {
    const onConfirmHandler = (e) => {
        e.preventDefault()
        onConfirm(options, cb)
    }

    elem.addEventListener('click', onConfirmHandler)
}

/**
 * Click and confirm listener.
 *
 * @param $elem     The jquery Element
 * @param options   The config for the confirm modal (see onConfirm)
 * @param cb        A callback to be executed if users confirms
 */
export function onClickAndConfirm$ ($elem, options, cb) {
    const onConfirmHandler = (e) => {
        e.preventDefault()
        onConfirm(options, cb)
    }

    $elem.on('click', onConfirmHandler)
}
