<template>
    <div style="position: relative;" ref="dropdown">
        <a
            data-toggle="tooltip"
            data-original-title="Tabelle anpassen"
            href="#"
            @click.prevent="optionsVisible = !optionsVisible"
        >
            <i class="mdi mdi-table-large c-layout__toolbar__control-icon"></i>
        </a>
        <div v-show="optionsVisible" class="dropdown data-table">
            <div class="row" style="height: 100%;">
                <div class="col-sm-5">
                    <app-data-table-sort />
                </div>
                <div class="col-sm-7 data-table__right-cell">
                    <app-data-table-filter />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTableFilter from './DataTableFilter.vue'
import DataTableSort from './DataTableSort.vue'

export default {
    data () {
        return {
            optionsVisible: false,
        }
    },

    created () {
        document.body.addEventListener('click', this.closeIfClickedOutside)
        document.addEventListener('keydown', this.closeOnEsc)
    },

    destroyed () {
        document.body.removeEventListener('click', this.closeIfClickedOutside)
        document.removeEventListener('keydown', this.closeOnEsc)
    },

    methods: {
        closeIfClickedOutside (event) {
            const isClickInside = this.$refs.dropdown.contains(event.target)
            if (!isClickInside) {
                this.optionsVisible = false
            }
        },

        closeOnEsc (event) {
            if (event.keyCode === 27) {
                this.optionsVisible = false
            }
        },
    },

    components: {
        AppDataTableFilter: DataTableFilter,
        AppDataTableSort: DataTableSort,
    },
}
</script>

<style scoped lang="scss">
    @import "~settings.scss";

    .dropdown {
        position: absolute;
        background-color: white;
        right: 0;
        z-index: 200;
        border: 1px solid gray;
        width: 520px;
        height: 365px;
        max-width: calc(100vw - 32px);
        padding: $spacing-unit-small $spacing-unit;
    }
</style>
