import {
    __, compose, contains, converge, curry, drop, dropLast, dropLastWhile,
    dropWhile, either, equals, filter, ifElse, intersection, is,
    isEmpty, join, last, map, split, startsWith, takeLastWhile, when,
} from 'ramda'

const $ = window.$


const goBackDelimiter = '____' // e.g. '____/____/contact/name'
const delimiter = '_' // sonata form ids are separated by '_', e.g.: 'course_detail_name'

/**
 * @sig String -> String
 * @example
 * removeTrailingDelimiter('bla_') // 'bla'
 * removeTrailingDelimiter('bla') // 'bla'
 */
const removeTrailingDelimiter = when(
    compose(equals(delimiter), last),
    dropLast(1),
)

/**
 * Given an id string of the mask field type compute and form field fragment,
 * compute the id of the form field fragment.
 *
 * In Symfony, every form field is associated with a (nested) id, e.g.
 * 'course_details_selectTitle'. In order to compute the id of the fields
 * that are masked by certain values of `selectTitle`, we need to take into account
 * the form field hierachy.
 *
 * If we want to mask a field `course_contact_name` than we pass the form field fragment
 * '____/____/contact/name' to `selectTitle`.
 *
 * WHY NOT PASS ID DIRECTLY? That would be much easier, BUT: Symfony computes the
 * id in the `finishView` function which cannot be accessed in Sonata Admin
 * classes ... *sigh
 *
 * @sig (String, String|Array) -> String
 * @example
 *
 * computeFieldId('abc_field_name', '____/text/text')
 * computeFieldId('abc_field_name_', '____/text/text')
 * computeFieldId('abc_field_name', ['____', 'text', 'text'])
 * // result is 'abc_field_text_text'
 */
export const computeFieldId = curry((basePath, pathArray) => {
    if (!is(Array, pathArray)) {
        return computeFieldId(basePath, split('/', pathArray))
    }

    if (isEmpty(pathArray)) {
        return removeTrailingDelimiter(basePath)
    }

    const [head, ...tail] = pathArray
    const newBasePath = head === goBackDelimiter
        ? dropLastWhile(x => x !== delimiter, removeTrailingDelimiter(basePath))
        : basePath + head + delimiter

    return computeFieldId(newBasePath, tail)
})

// getMainFormName :: jQUery Elem -> String
export const getMainFormName = $elem => dropLastWhile(x => x !== delimiter, $elem.attr('id'))

// getFieldName :: jQuery Elem -> String
export const getFieldName = $elem => takeLastWhile(x => x !== delimiter, $elem.attr('id'))

/**
 * Same as getFromFormBySonataSelector, but returns surrounding container
 * (the container is usually the div with the form-group class and needs
 * to be accesed e.g. by the lock type).
 *
 * @sig jQuery Elem jQ => jQ -> String | [String] -> jQ
 */
export const getContainerBySonataSelector = curry(($elem, sonataSelector) => compose(
    id => $(`#sonata-ba-field-container-${id}`),
    computeFieldId(__, sonataSelector),
    getMainFormName,
)($elem))

/**
 * For a sonata form element (those with id of the form 's5a900203db655_zusatzinformation_alumni')
 * find the jQuery elem given by the sonata selector.
 *
 * If sonata selector is '../name' you will get the element with id
 * 's5a900203db655_name'
 * @sig jQuery Elem jQ => jQ -> String | [String] -> jQ
 */
export const getFromFormBySonataSelector = curry(($elem, sonataSelector) => compose(
    (id) => {
        const $elem = $(`#${id}`)
        if ($elem.length === 0) {
            console.error(`Sonata field "${sonataSelector}" could not be found, because the id "${id}" does not exist.`)
        }

        return $elem
    },
    computeFieldId(__, sonataSelector),
    getMainFormName,
)($elem))

/**
 * We allow selectors containing a wildcard, such as `#cost_*_name`.
 */
export const WILDCARD = '*'

// isWildcard :: String -> Boolean
const isWildcard = x => x !== WILDCARD

/**
 * For an id selector with a wildcard `*` character, return all elements with
 * id that matches the selector up to the wildcard and beginning from
 * the wildcard character.
 *
 * Say, you have three elements with id `cost_1_name`, `cost_2_name` and `cost_3_name`
 * Then you can get them via `findByWildcardSelector('#cost_*_name')`.
 *
 * @sig String -> [jQuery]
 * @example
 * findByWildcardSelector('#12345_cost_*_name')
 */
export const findByWildcardSelector = converge(
    compose(map(x => $(x)), intersection),
    [
        compose(
            firstPart => $(`[id^=${firstPart}]`).get(),
            when(startsWith('#'), drop(1)),
            dropLast(1), // drop wildcard
            dropLastWhile(isWildcard),
        ),
        compose(
            lastPart => $(`[id$=${lastPart}]`).get(),
            drop(WILDCARD.length),
            dropWhile(isWildcard),
        ),
    ],
)

/**
 * Given an id selector that may contain a wildcard, return all matching
 * jquery elements in an array.
 *
 * @sig String -> [jQuery]
 * @see findByWildcardSelector
 */
export const findMatchingElements = compose(
    filter($elem => $elem.length), // avoid empty jquery elements
    ifElse(
        contains(WILDCARD),
        findByWildcardSelector,
        selec => [$(selec)],
    ),
)

// getParentForm :: jQuery Elem -> jQuery Elem
export const getParentForm = $elem =>
    getFromFormBySonataSelector($elem, [getFieldName($elem), goBackDelimiter])

/**
 * Get child of a lockindicator field.
 *
 * From a field with id: `s5a9fe589c7d09_kosten_text` query for the field with
 * id `s5a9fe589c7d09_kosten_text_text`.
 *
 * @sig jQuery Elem -> jQuery Elem
 */
export const getLockIndicatorChild = ($elem) =>
    getFromFormBySonataSelector($elem, [getFieldName($elem), getFieldName($elem)])

/**
 * For a sonata form element return whether it is a radio button group.
 *
 * @sig jQuery Elem -> Boolean
 */
export const isRadioButtonGroup = $form =>
    $form.hasClass('js-checkbox-group') && $form.find('input[type="radio"]').length > 0

/**
 * For a sonata form element return whether it is a checkbox group.
 *
 * @sig jQuery Elem -> Boolean
 */
export const isCheckboxGroup = $form =>
    $form.hasClass('js-checkbox-group') && $form.find('input[type="checkbox"]').length > 0

// isMultiple :: jQuery Elem -> Bool
export const isMultiple = either(isRadioButtonGroup, isCheckboxGroup)

/**
 * Does this field belong to the cke rich text editor?
 *
 * @sig jQUeyr Elem -> Bool
 */
export const isRTEField = $elem => $elem.hasClass('js-cke')

// isVichFiledWithUpload :: jQuery Elem -> Boolean
export const isVichFiledWithUpload = $elem =>
    $elem.hasClass('js-vich-image') && $elem.data('has-image')

// isImageCropHelperField :: jQuery Elem -> Boolean
export const isImageCropHelperField = $elem => $elem.hasClass('js-presta-image-helper')

// isLockindicator :: jQuery Elem -> Bool
export const isLockindicator = $elem => $elem.hasClass('js-is-lockindicator')

// valueOfFormField :: jQuery Elem -> String|[String]
export const valueOfFormField = ($form) => {
    if (isRadioButtonGroup($form)) {
        return $form.find('input:checked').val() || ''
    }

    if (isCheckboxGroup($form)) {
        return $form.find('input:checked')
            .map(function () { return $(this).val() })
            .get()
    }

    if ($form.is(':checkbox')) {
        return $form.prop('checked') ? 'true' : ''
    }

    return $form.val() || ''
}

// setValueOfFormField :: jQuery -> String | [String] -> jQuery
export const setValueOfFormField = ($form, value) => {
    if (isRadioButtonGroup($form)) {
        $form.find($('input:radio'))
            .prop('checked', false)
            .filter(`[value=${value}]`)
            .prop('checked', true)

        return $form
    }

    if (isCheckboxGroup($form) && is(Array, value)) {
        $form.find('input:checked').map(function () {
            const $input = $(this)
            $input.prop('checked', value.includes($input.val()))
        })
        return $form
    }

    $form.val(value)

    return $form
}

// stringValueOfFormField :: jQuery Elem -> String
export const stringValueOfFormField = compose(
    when(is(Array), join(',')),
    valueOfFormField,
)

/**
 * Does the input field belong to a Select 2 component?
 *
 * @sig jQuery elem -> Boolean
 */
export const belongsToSelect2 = ($elem) =>
    $elem.hasClass('select2-focusser') ||
    $elem.hasClass('select2-offscreen') ||
    $elem.hasClass('select2-input')

// needsClientValidation :: jQuery Elem -> Boolean
export const needsClientValidation = $elem =>
    $elem.hasClass('required-field') || $elem.attr('required') === 'required'

// setFormFieldError :: jQuery elem -> undefined
export const setFormFieldError = $elem =>
    $elem.closest('.form-group').addClass('has-error')

// setFormFieldCorrect :: jQuery elem -> undefined
export const setFormFieldCorrect = $elem =>
    $elem.closest('.form-group').removeClass('has-error')

// setFormFieldNonEmptyError :: jQuery elem -> undefined
export const setFormFieldNonEmptyError = $elem =>
    $elem.closest('.form-group').addClass('has-error has-error--non-empty')
