const $ = window.$
const $viewport = $(window)
const $content = $('.c-layout__content')
const $sidebar = $('.js-layout .c-layout__aside')
const $navigationBar = $('.navbar-static-top')

/**
 * Add class to the right sidebar when it hits the top navigation bar.
 * This will make it possible to make the position of the sidebar fixed via SCSS.
 * This glitches if the content on the left ist to short so we makte that at least as high as the viewport
 */
const FixedSidebar = (function fxiSidebar () {
    const init = () => {
        if ($sidebar.length >= 1) {
            $viewport.scroll((e) => {
                var windowScrollPosition = $viewport.scrollTop()
                var windowHeight = $viewport.outerHeight()
                var contentOffsetTop = $content.offset().top
                var navigationBarHeight = $navigationBar.outerHeight()
                var switchToFixedNavigationThreshold = contentOffsetTop - navigationBarHeight

                $content.css('min-height', windowHeight)

                if (windowScrollPosition >= switchToFixedNavigationThreshold) {
                    $sidebar.addClass('c-layout__aside--fixed')
                    $sidebar.css('height', windowHeight - navigationBarHeight)
                } else {
                    $sidebar.removeClass('c-layout__aside--fixed')
                    $sidebar.removeAttr('style')
                }
            })
        }
    }

    return { init }
})()

export default FixedSidebar
