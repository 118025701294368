const $ = window.$


const initToasts = () => {
    $('.js-toast-close').click((e) => {
        const $toast = $($(e.target).parents('.js-toast')[0])
        if ($toast.hasClass('js-toast-hide')) {
            $toast.addClass('hidden')
        } else {
            $toast.remove()
        }
    })

    setTimeout(() => {
        $('.js-toast.c-toast--success').fadeOut()
    }, 10000)
}

export default { init: initToasts }
