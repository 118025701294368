import { onClickAndConfirm$ } from '@/util/confirm'

const $ = window.$


const confirmDeleteOptions = {
    msg: 'Möchten Sie die Datei wirklich löschen?',
    title: '',
    cancel: 'Abbrechen',
    submit: 'Löschen',
}

const initCopyField = ($deleteBtn) => {
    const $parentForm = $deleteBtn.closest('form')
    const $deleteCheckbox = $(`#${$deleteBtn.data('delete-checkbox-id')}`)

    onClickAndConfirm$($deleteBtn, confirmDeleteOptions, () => {
        // if checkbox is checked, submitting the form triggers deletion of file
        $deleteCheckbox.prop('checked', true)
        $parentForm.submit()
    })
}

/**
 * Js Component for handling deletion of files.
 */
export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-delete-file-btn'))
            .forEach(x => initCopyField($(x)))
    },
}
