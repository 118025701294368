import { getFromFormBySonataSelector } from '@/util/sonataHelper'

const $ = window.$


/**
 * Jump to same date as linked date input
 */
const jumpToDate = ($dateField) => {
    const updateDate = () => {
        if (!$dateField.val()) {
            return
        }

        // Get date
        const sourceDatePicker = $dateField.parent('.input-group.date').data('DateTimePicker')
        const dateToSet = sourceDatePicker.getDate()

        // Set date
        const $targetDateInput =
            getFromFormBySonataSelector($dateField, $dateField.data('sonata-selector'))
        const targetDatePicker = $targetDateInput.parent('.input-group.date').data('DateTimePicker')

        targetDatePicker.setMinDate(dateToSet)
        if ($targetDateInput.val() === '' || sourceDatePicker.getDate() > targetDatePicker.getDate()) {
            targetDatePicker.setDate(dateToSet)
        }
    }

    $dateField.on('change', updateDate)
}

const MinDate = ($dateField) => {
    const updateDate = () => {
        if (!$dateField.val()) {
            return
        }

        // Get date
        const sourceDatePicker = $dateField.parent('.input-group.date').data('DateTimePicker')
        const mindateToSet = sourceDatePicker.options.minDate
        let dateValueOnline = new Date($dateField.context.value.replace(/(\d+).(\d+).(\d+)/, '$3-$2-$1'))
        if ($dateField.context.value.length <= 7) {
            dateValueOnline = new Date($dateField.context.value.replace(/(\d+).(\d+)/, '$2-$1'))
        }
        // Set date
        if (dateValueOnline < mindateToSet) {
            sourceDatePicker.setDate(mindateToSet)
        }
    }
    $dateField.on('change', updateDate)
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-calendar-jump-to-date'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => jumpToDate($(x)))

        Array.from(root.querySelectorAll('.js-date-time-picker-type'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => MinDate($(x)))
    },
}
