import Form from '../form/Form'
import { onConfirm, onConfirmOrCancel, getConfirmOptions } from '@/util/confirm'
import ExportFilter from './ExportFilter'

const $ = window.$


/**
 * Add a click listener to selector and call callback on the element if
 * it has beend clicked and the user has confirmed the confirmation dialog.
 *
 * @param root  The root element for the click listsner
 * @param selector  A string specifying the selector, e.g. '.js-on-confirm'
 * @param cb  Callback function on the clicked element
 * @see onConfirm, getConfirmOptions
 */
const onClickAndConfirm = (root = document.body, selector, cb) => {
    $(root).on('click', selector, function (e) {
        e.preventDefault()
        const $elem = $(this)
        const confirmOptions = getConfirmOptions($elem)

        onConfirm(confirmOptions, () => cb($elem))
    })
}

/**
 * Add a click listener to selector and call callback on the element if
 * it has beend changed and the user has confirmed the confirmation dialog.
 *
 * @param root  The root element for the click listsner
 * @param selector  A string specifying the selector, e.g. '.js-on-select-and-confirm'
 * @param cb  Callback function on the clicked element
 * @see onConfirm, getConfirmOptions
 */
const onSelectAndConfirm = (root = document.body, selector, cb) => {
    $(root).on('change', selector, function (e) {
        e.preventDefault()
        const $elem = $(this)
        if ($elem.prop('value') === '1') {
            const confirmOptions = getConfirmOptions($elem)
            onConfirmOrCancel(confirmOptions, () => cb(), () => $elem.prop('value', '').trigger('change'))
        }
    })
}

/**
 * Links with the class `.js-on-confirm` will open a confirmation modal
 * before navigating to `href`.
 *
 * You may optionally specify the following data-attributes:
 * - data-msg: Message such as: "Do you really want to delete X"
 * - data-submit: Text for submit button
 * - data-cancel: Text for cancel button
 * - data-title: Text for modal Title (is empty by default)
 *
 * Example:
 * <a href="https://example.com" class="js-on-confirm" data-msg="Navigate away?">Go</a>
 */
const initOnConfirm = (root = document.body) =>
    onClickAndConfirm(root, '.js-on-confirm', ($link) => {
        window.location = $link.attr('href')
    })

/**
 * The class `.js-on-select-and-confirm` will open a confirmation modal
 *
 * You may optionally specify the following data-attributes:
 * - data-msg: Message such as: "Do you really want to delete X"
 * - data-submit: Text for submit button
 * - data-cancel: Text for cancel button
 * - data-title: Text for modal Title (is empty by default)
 */
const initOnSelectAndConfirm = (root = document.body) =>
    onSelectAndConfirm(root, '.js-on-select-and-confirm', () => {
        return true
    })

/**
 * Links with the class `.js-confirm-and-delete` will open a confirmation modal
 * and submit the parent form if user confirms.
 *
 * You may optionally specify the following data-attributes:
 * - data-msg: Message such as: "Do you really want to delete X"
 * - data-submit: Text for submit button
 * - data-cancel: Text for cancel button
 * - data-title: Text for modal Title (is empty by default)
 *
 * Deletion with Sonata requires the following steps:
 * - make form with DELETE request and csrf token and append to body
 * - on click show confirm dialog
 * - on confirm submit the form.
 *
 * @example
 *  <a
 *      href="/admin/bachelormasterphd/user/6/delete"
 *      class="js-confirm-and-delete"
 *      data-csrf-token="yhIvLt9ISYXr0mzmPpVeE6RNiIkCWysQisW4HpOLemk"
 *      data-url="/admin/bachelormasterphd/user/6/delete"
 *      data-msg="Möchten Sie diesen Datensatz wirklich löschen?"
 *      data-submit="Ja"
 *      data-cancel="Abbrechen"
 *  >Löschen</a>
 */
const initDeleteButtons = (root = document.body) => {
    $(root).find('.js-confirm-and-delete').each(function (key) {
        const $deleteLink = $(this)
        const formId = `js-delete-form-${key}`
        const url = $deleteLink.data('url')
        const csrfToken = $deleteLink.data('csrf-token')

        $deleteLink.attr('data-form-id', formId)

        // sonata way of simulating a DELETE request
        const form = `
            <form method="POST" action="${url}" class="hidden" id="${formId}">
                <input type="hidden" name="_sonata_csrf_token" value="${csrfToken}">
                <input type="hidden" name="_method" value="DELETE">
            </form>
        `
        $('body').append(form)
    })

    onClickAndConfirm(root, '.js-confirm-and-delete', ($link) => {
        const formId = $link.data('form-id')
        $(`#${formId}`).submit()
    })
}

/**
 * Initialize basic jquery components for the admin interface.
 */
export default (() => {
    const init = (root = document.body) => {
        initOnConfirm(root)
        initOnSelectAndConfirm(root)
        initDeleteButtons(root)
        Form.init(root)
        ExportFilter.init()
    }

    return { init }
})()
