import { assoc, assocPath, map, flip, pathOr, partial, propOr } from 'ramda'

/**
 * Simple wrapper for using the localStorage api.
 */
const storage = (function () {
    /**
     * Get an item from the storage
     */
    const get = (key, defaultValue = null) =>
        JSON.parse(localStorage.getItem(key)) || defaultValue

    /**
     * Remove the key from the storage.
     */
    const remove = (key) => {
        localStorage.removeItem(key)
    }

    /**
     * Get item from storage and make sure its an array.
     */
    const getArray = (key, defaultValue = []) => {
        const storedItem = get(key)

        return Array.isArray(storedItem) ? storedItem : defaultValue
    }

    /**
     * Get item from local storage and make sure its boolean
     */
    const getBoolean = (key, defaultValue = true) => {
        const val = JSON.parse(localStorage.getItem(key))
        const storedItem = val === null || typeof val === 'undefined'
            ? defaultValue : val

        return Boolean(storedItem)
    }

    /**
     * Set item to storage.
     */
    const set = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
    }

    /**
     * Set item at a property of the storage.
     */
    const setProp = (key, propName, value) => {
        const currentObj = get(key, {})
        set(key, assoc(propName, value, currentObj))
    }

    return {
        get,
        remove,
        getArray,
        getBoolean,
        set,
        setProp,
    }
})()

/**
 * Storage with a predefined key. EFfectively binds the key to first argument of
 * all functions of Storage.
 *
 * const st = singleKeyStorage('myFancyKey')
 * st.get()
 * st.set(value)
 */
export const singleKeyStorage = (key) =>
    map(flip(partial)([key]), storage)

/**
 * Helper for storing nested objects in LocalStorage. Returns a storage object
 * that stores/retrieves data in property of an object.
 *
 * @sig (SingleKeyStorage, String) -> { get, getProp, set, setProp }
 * @example
 * Single = singleKeyStorage('bar')
 * PropStorage = propStorage(Single, 'foo')
 * PropStorage.set({ a: 'b' }) // store { foo: { a: 'b'}} at key 'bar'
 */
export const propStorage = (SingleKeyStorage, propName) => ({
    // get :: a -> b
    get: (defaultValue = null) =>
        propOr(defaultValue, propName, SingleKeyStorage.get()),

    getProp: (innerProp, defaultValue = null) =>
        pathOr(defaultValue, [propName, innerProp], SingleKeyStorage.get()),

    set: value => SingleKeyStorage.setProp(propName, value),

    setProp: (innerProp, value) => {
        const currentObj = SingleKeyStorage.get({})
        SingleKeyStorage.set(assocPath([propName, innerProp], value, currentObj))
    },
})

export default storage
