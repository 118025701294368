import { onTwoButtonConfirm } from '@/util/confirm'

const $ = window.$
const submitButtonSelector = '#info_during_first_login'

/**
 * - confirmation on submit
 */
const initShowInfo = (root = document.body) => {
    if (!$(submitButtonSelector).length) {
        return
    }

    $(window).on('load', function (e) {
        e.preventDefault()

        const confirmOptions = {
            title: '',
            msg: '<p style="font-size: 2rem"><b>Passen Sie Ihre wichtigen Einstellungen an</b></p><p>Als Koordinator bzw. Koordinatorin können Sie in den "Vereinheitlichten Informationen" wichtige Einstellungen für Ihr gesamtes Eintragsangebot festlegen:</p> ' +
                '<ul><li><b>Welche Inhalte in allen Einträgen verbindlich ausgegeben werden </b></li>' +
                '<li><b>Ob Nutzerinnen und Nutzer ihre Einträge direkt an den DAAD freigeben dürfen </b></li>' +
                '<li><b>Ob Sie eine Sammelrechnung erhalten (empfohlen ab 3 Einträgen) </b></li></ul>',
            submit: 'Einstellungen anpassen',
            cancel: 'Mehr dazu in der Hilfe',
        }
        const userId = $(submitButtonSelector).attr('data-key')
        onTwoButtonConfirm(confirmOptions, (btnType) => {
            $.ajax({
                type: 'GET',
                url: `/app/ajax/check-coordinator-info/${userId}/${btnType}`,
                success (url) {
                    window.location.href = url
                },
            })
        })
    })
}

const showInfoForFirstLogin = (function initShowInfoField () {
    return { init: initShowInfo }
})()

export default showInfoForFirstLogin
