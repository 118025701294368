import initCropper from '@/overrides/prestaCropper'

const $ = window.$

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-cropper')).forEach((elem) => {
            initCropper($(elem))
        })
    },
}
