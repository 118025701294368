import { isNil } from 'ramda'

const $ = window.$


/**
 * Component for showing active tab on page load
 *
 * From https://github.com/sonata-project/SonataAdminBundle/issues/1911
 */
const initRememberTabs = () => {
    // Show active tab on reload
    if (location.hash !== '' && location.hash.substring(1, 5) === 'tab_') {
        const currentTabNumber = location.hash.substring(5)
        const adminUniqId = getAdminUniqId()

        $('a[href="#tab_' + adminUniqId + '_' + currentTabNumber + '"]').tab('show')
    }

    // Remember the hash in the URL without jumping
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        const tabName = $(e.target).attr('href').substring(1)
        const tabParts = tabName.match(/tab_\w+_(\d)/)
        const tabHashName = '#tab_' + tabParts[1]

        if (history.pushState) {
            history.pushState(null, null, tabHashName)
        } else {
            location.hash = tabHashName
        }
    })

    // Add the hash in action form when submit
    $('form').on('submit', function () {
        if (location.hash.substring(1, 5) === 'tab_') {
            $(this).attr('action', $(this).attr('action') + location.hash)
        }
    })
}

const getAdminUniqId = () => {
    const tabHref = $('a[href^="#tab_').attr('href')
    if (isNil(tabHref)) {
        return ''
    }
    const tabHrefComponents = tabHref.replace('#tab_', '').split('_')

    if (!tabHrefComponents[0]) {
        return ''
    }

    return tabHrefComponents[0]
}

export default { init: initRememberTabs }
