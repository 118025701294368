import { keys, pluck } from 'ramda'
import { getFromFormBySonataSelector } from '@/util/sonataHelper'
import { onConfirm } from '@/util/confirm'

const $ = window.$


/**
 * Copy input field value from one input field to another
 *
 * IMPORTANT: right now this only works because all fields are simple input fields
 * This has not been tested for radio or select fields.
 *
 * @sig jQuery Elem Jq => (Jq, Jq) -> *
 */
const copyFormField = ($to, $from) => {
    if ($to.attr('disabled') !== 'disabled') {
        $to.val($from.val())
        $to.trigger('change') // for FormTabs
    }
}

// clearFormField :: jQuery Elem -> *
const clearFormField = ($field) => {
    if ($field.attr('disabled') !== 'disabled') {
        $field.val('')
        $field.trigger('change') // for FormTabs
    }
}

const clearModalOptions = {
    msg: 'Möchten Sie die übernommenen Daten der Felder beibehalten oder entfernen?',
    title: '',
    cancel: 'Beibehalten',
    submit: 'Entfernen',
}

const initCopyField = ($copyField) => {
    // getBySonataSelector :: String -> jQuery Elem
    const getBySonataSelector = getFromFormBySonataSelector($copyField)

    // copyMap :: { k: String }
    const copyMap = $copyField.data('copy-map')

    // fieldMap :: [{ $to: jQuery, $from: jQuery }]
    const fieldMap = keys(copyMap).map(from => ({
        $from: getBySonataSelector(from),
        $to: getBySonataSelector(copyMap[from]),
    }))

    $copyField.on('change', () => {
        if ($copyField.prop('checked')) {
            fieldMap.forEach(({ $to, $from }) => copyFormField($to, $from))
        } else {
            onConfirm(clearModalOptions, () => {
                pluck('$to', fieldMap).forEach(clearFormField)
            })
        }
    })
}

/**
 * Js Component for copying the specified input fields to some other input fields
 * of a checkbox is checked.
 */
export default {
    init: (root = document.body) => {
        Array.from(root.querySelectorAll('.js-copy-if-checked'))
            .forEach(x => initCopyField($(x)))
    },
}
