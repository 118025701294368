import { isRadioButtonGroup, isRTEField } from '@/util/sonataHelper'

const $ = window.$


const isLockedClass = 'mdi-lock'
const isUnlockedClass = 'mdi-lock-open-outline'
const DEFAULTS_LSP = '1'

const lockedTitle = 'Dieses Feld kann durch die Fachreferate nicht editiert werden. Klicken Sie auf das Schloß, um die Bearbeitung zuzulassen.'
const unlockedTitle = 'Dieses Feld kann durch die Fachreferate editiert werden. Klicken Sie auf das Schloß, um die Bearbeitung zu unterbinden.'
const lockedTitleLsp = 'Dieses Feld kann in den einzelnen Kursen nicht editiert werden. Klicken Sie auf das Schloss, um die Bearbeitung zuzulassen.'
const unlockedTitleLsp = 'Dieses Feld kann in den einzelnen Kursen editiert werden. Klicken Sie auf das Schloss, um die Bearbeitung zu unterbinden.'


const renderIcon = ($lockType) => {
    const $icon = $lockType.find('.js-lock-field')
    const $target = $(`#${$icon.data('input-target-id')}`)
    const $parent = isRTEField($target)
        ? $target.closest('.sonata-ba-field').find('div') // required for css to work with multiple icons
        : $target.closest('.sonata-ba-field')

    $target.closest('.sonata-ba-field').addClass('lock-type')
    $icon.addClass('lock-type__field')
    if (isRadioButtonGroup($target)) {
        $icon.addClass('lock-type__field--radio')
    }

    if (isRTEField($target)) {
        $icon.addClass('lock-type__field--rte')
    }

    return $icon.detach().prependTo($parent)
}

const findIconAndCheckbox = ($lockType) => {
    const $icon = $lockType.find('.js-lock-field')
    const $checkbox = $lockType.find(`#${$icon.data('checkbox-target-id')}`)

    return { $icon, $checkbox }
}

export const lockIcon = ($icon) => {
    $icon.removeClass(isUnlockedClass)
    $icon.addClass(isLockedClass)
    if ($('.js-defaults-type').val() === DEFAULTS_LSP) {
        $icon.prop('title', lockedTitleLsp)
    } else {
        $icon.prop('title', lockedTitle)
    }
}

export const unlockIcon = ($icon) => {
    $icon.removeClass(isLockedClass)
    $icon.addClass(isUnlockedClass)
    if ($('.js-defaults-type').val() === DEFAULTS_LSP) {
        $icon.prop('title', unlockedTitleLsp)
    } else {
        $icon.prop('title', unlockedTitle)
    }
}

export const lockField = ($lockType) => {
    const { $icon, $checkbox } = findIconAndCheckbox($lockType)

    lockIcon($icon)
    $checkbox.prop('checked', true)
}

const updateIcon = ($icon, isLocked) => {
    if (isLocked) {
        lockIcon($icon)
    } else {
        unlockIcon($icon)
    }

    $icon.tooltip('hide').tooltip('fixTitle')
}

const initLockFieldType = (lockType) => {
    const $lockType = $(lockType)
    const $icon = renderIcon($lockType)
    const { $checkbox } = findIconAndCheckbox($lockType)

    updateIcon($icon, $checkbox.prop('checked'))

    // Contact type fields must always lock or unlock together
    if ($icon.parent().find('.js-lock-field-contact-type-main').length) {
        $icon.parent().find('.js-lock-field-contact-type-main').change(() => {
            initLockForDependentFields('.js-lock-field-contact-type', $icon.parent().find('.js-lock-field-contact-type-main'), $checkbox.prop('checked'))
        })
    }

    // IvoiceFormatX and invoiceIdFormatX fields must always lock or unlock together
    if ($icon.parent().find('.js-lock-field-invoice-FormatX-main').length) {
        $icon.parent().find('.js-lock-field-invoice-FormatX-main').change(() => {
            initLockForDependentFields('.js-lock-field-invoice-FormatX', $icon.parent().find('.js-lock-field-invoice-FormatX-main'), $checkbox.prop('checked'))
        })
    }

    $icon.click(() => {
        $checkbox.prop('checked', (_, val) => !val)
        updateIcon($icon, $checkbox.prop('checked'))
        // Contact type fields must always lock or unlock together
        if ($icon.parent().find('.js-lock-field-contact-type').length) {
            initLockForDependentFields('.js-lock-field-contact-type', $icon.parent().find('.js-lock-field-contact-type'), $checkbox.prop('checked'))
        }

        // IvoiceFormatX and invoiceIdFormatX fields must always lock or unlock together
        if ($icon.parent().find('.js-lock-field-invoice-FormatX').length) {
            initLockForDependentFields('.js-lock-field-invoice-FormatX', $icon.parent().find('.js-lock-field-invoice-FormatX'), $checkbox.prop('checked'))
        }
    })
}

const initLockType = (root = document.body) => {
    Array.from(root.querySelectorAll('.js-locktype')).forEach((lockType) => {
        initLockFieldType(lockType)
    })

    /**
     * Lock types with two icons need different paddings. Evaluating
     * if a form field has two icons is much more complicated on the server
     * side. Thats why we do it here.
     */
    Array.from(root.querySelectorAll('.lock-type')).forEach((el) => {
        if (el.querySelectorAll('.lock-type__field').length === 2) {
            el.classList.add('lock-type--two-icons')
        }
    })
}

const initLockForDependentFields = (selector, $baseField, val) => {
    Array.from(document.body.querySelectorAll(selector)).forEach((lockField) => {
        const $lockField = $(lockField)
        const $lockType = $lockField.closest('.js-locktype')
        const $icon = renderIcon($lockType)
        const { $checkbox } = findIconAndCheckbox($lockType)

        if ($baseField.get(0) !== $lockField.get(0) && $lockField.closest('.js-form-row').css('display') !== 'none') {
            $checkbox.prop('checked', val)
            updateIcon($icon, $checkbox.prop('checked'))
        } else if ($baseField.get(0) !== $lockField.get(0)) {
            $checkbox.prop('checked', false)
            updateIcon($icon, $checkbox.prop('checked'))
        }
    })

    // Set unlock for hidden fields. setTimeout is need, because fields use animation to be hidden
    setTimeout(() => {
        Array.from(document.body.querySelectorAll(selector)).forEach((lockField) => {
            const $lockField = $(lockField)
            const $lockType = $lockField.closest('.js-locktype')
            const $icon = renderIcon($lockType)
            const { $checkbox } = findIconAndCheckbox($lockType)

            if ($baseField.get(0) !== $lockField.get(0) && $lockField.closest('.js-form-row').css('display') === 'none') {
                $checkbox.prop('checked', false)
                updateIcon($icon, $checkbox.prop('checked'))
            }
        })
    }, 500)
}

const LockType = (function initLockTypeField () {
    return { init: initLockType }
})()

export default LockType
