const $ = window.$


// countNewLines :: String -> Number
const countNewLines = string => string.split('\n').length - 1

/**
 * Newline characters count as 2 on the server, while they count only as one
 * on the client side.
 *
 * @sig String -> Number
 */
const numCharsOnServer = string => string.length + countNewLines(string)

/**
 * Renders the counter and returns a function to update the counter.
 *
 * @sig { maxLength: Number, formField: Dom Element } -> { lenght: Number }-> undefined
 */
const renderDOM = ({ maxLength, formField }) => {
    const $counterOf = $(`<span
            class="js-max-count-wrapper small-text c--secondary-text"
            style="position: absolute; bottom: 3px; right: 0;"
        >
            <span class="js-max-count-counter">0</span>/${maxLength}
        </span>
    `)

    const $formField = $(formField)
    $formField.wrap('<div style="position: relative;"></div>')
    $counterOf.insertAfter($formField)

    const $counter = $counterOf.find('.js-max-count-counter')

    // updateDOM :: { length: Number } -> undefined
    const updateDOM = ({ length }) => {
        $counter.html(length)
        if ($formField.prop('tagName') === 'INPUT') {
            $formField.css({ 'padding-right': `${$counterOf.width() + 15}px` })
        }
    }

    updateDOM({ length: numCharsOnServer(formField.value) })

    return updateDOM
}

/**
 * Add a counter to each textarea/input-field and prevents adding more characater
 * than the allowed number of characters. The counter is incremented while typing.
 *
 * @example
 * <textarea class="js-max-count" maxlength="200"></textarea>
 * <input type="text" class="js-max-count" maxlength="200" ...>
 */
const textareaCounter = (root = document.body) => {
    Array.from(root.querySelectorAll('.js-max-count')).forEach((formField) => {
        const $formField = $(formField)
        const maxLength = formField.getAttribute('maxlength')
        const updateDOM = renderDOM({ maxLength, formField })

        let _currentValue = formField.value
        let _currentLength = numCharsOnServer(_currentValue)

        const onChange = () => {
            const newValue = formField.value
            const newLength = numCharsOnServer(newValue)
            if (newLength > maxLength && newLength > _currentLength) {
                // reset if length is too long, but allow deletion of characters
                formField.value = _currentValue
            } else {
                _currentValue = newValue
                _currentLength = newLength
            }

            updateDOM({ length: _currentLength })
        }

        $formField.on('input', onChange)
        $formField.on('change', onChange)

        $(window).load(() => {
            updateDOM({ length: _currentLength })
        })
    })
}

export default { init: textareaCounter }
