import { allPass, has, is, isNil } from 'ramda'
import { singleKeyStorage } from '../../util/storage'

const SCROLL_POSITION_FORM = singleKeyStorage('scroll-position-after-form-save')
const SCROLL_POSITION_LIST = singleKeyStorage('scroll-position-after-list-action')
const FORM = '.sonata-ba-form form'

const $ = window.$

/**
 * Return true if object stored in localStorage has correct format.
 *
 * @sig a -> Boolean
 */
const isValid = allPass([
    x => !isNil(x),
    is(Object),
    has('pos'),
    has('url'),
])

const saveScrollPosition = () => {
    saveScrollPositionOnFormSave()
    saveScrollPositionOnListAction()

    $(window).load(() => {
        setTimeout(function () {
            if ($('.sonata-ba-form').length) {
                loadScrollPosition(SCROLL_POSITION_FORM)
            } else {
                loadScrollPosition(SCROLL_POSITION_LIST)
            }
        }, 2000)
    })
}

const saveScrollPositionOnFormSave = () => {
    $(FORM).submit(() => {
        saveCurrentScrollPosition(SCROLL_POSITION_FORM)
    })
}

const saveScrollPositionOnListAction = () => {
    $('body').on('click', '.js-cell-actions a', (e) => {
        saveCurrentScrollPosition(SCROLL_POSITION_LIST)
    })
}

const saveCurrentScrollPosition = (storage) => {
    storage.set({
        pos: $(window).scrollTop(),
        url: window.location.pathname,
    })
}

const loadScrollPosition = (storage) => {
    const stored = storage.get()
    if (isValid(stored) && stored.url === window.location.pathname) {
        window.scrollTo(0, stored.pos)

        // Reset, so when a user navigates directly to another form no scrolling is happening.
        storage.remove()
    }
}

export default { init: saveScrollPosition }
