import {
    contains, curry, either, equals, ifElse, is, isEmpty, isNil,
} from 'ramda'

import { onConfirm, getConfirmOptions } from '@/util/confirm'
import { valueOfFormField } from '@/util/sonataHelper'

const $ = window.$


// isNilOrEmpty :: a -> Boolean
const isNilOrEmpty = either(isNil, isEmpty)

// containsValue :: String | [String] -> String -> Boolean
const containsValue = curry((formValue, singleValue) => ifElse(
    is(Array),
    contains(singleValue),
    equals(singleValue),
)(formValue))

const checkConfirmationOnSubmit = (form) => {
    /**
     * If the confirmConfig is not null, then the confirmation modal
     * is opened when user tries to submit the form.
     *
     * @sig null | { msg, title?, cancel?, submit? }
     */
    let _confirmConfig = null

    // confirmFields :: [jQuery]
    const confirmFields = Array.from(form.querySelectorAll('.js-form-confirm-submit'))
        .map(x => $(x))
        .filter($el => !isNilOrEmpty($el.data('confirm-value')) && !isNilOrEmpty($el.data('msg')))

    if (confirmFields.length === 0) return
    if (confirmFields.length > 1) {
        console.warn('The form has multiple fields that can trigger confirmation on submit. This case is not fully implemented.', confirmFields)
    }

    confirmFields.forEach($el => {
        const localConfirmConfig = getConfirmOptions($el)
        const confirmValue = `${$el.data('confirm-value')}` // ensure string
        const initalFormValue = valueOfFormField($el) // can be array
        if (containsValue(initalFormValue, confirmValue)) {
            // dont do anything because field already has the confirmed value
            return
        }

        $el.on('change', () => {
            if (containsValue(valueOfFormField($el), confirmValue)) {
                _confirmConfig = localConfirmConfig
            } else {
                _confirmConfig = null
            }
        })
    })

    const $form = $(form)
    $form.submit((e) => {
        if (isNilOrEmpty(_confirmConfig)) return

        e.preventDefault()
        onConfirm(_confirmConfig, () => {
            _confirmConfig = null
            $form.submit()
        })
    })
}

/**
 * If a form has a field with a class .js-form-confirm-submit, then this
 * field can prevent the submit for the form and show a confirmation modal
 * instead.
 *
 * The data attributes data-title, data-confirm and data-cancel are optional.
 *
 * @example
 * <form ...>
 *     <select
 *         class="js-form-confirm-submit"
 *         data-msg="Really?"
 *         data-confirm-value="1"
 *     >
 *         <option value="1">Accepted</option>
 *         <option value="2">Something else</option>
 *     </select>
 * </form>
 */
export default {
    init (root) {
        Array.from(root.querySelectorAll('form'))
            .forEach(checkConfirmationOnSubmit)
    },
}

