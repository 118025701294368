import Vue from 'vue'

/**
 * Wrapper utility for initializing a vue component.
 *
 * @param selector  A string specifying the selector
 * @param options  The options for the Vue component
 */
export const initVueComponent = (selector, options) => {
    if (document.querySelector(selector)) {
        /* eslint-disable no-new */
        new Vue({
            el: selector,
            ...options,
        })
        /* eslint-enable no-new */
    }
}
