const $ = window.$


const initInputFormatter = ($field) => {
    $field.on('propertychange input', () => {
        $field.val($field.val().replace(/[^\d]+/g, ''))
    })
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-force-numeric'))
            .map(x => $(x))
            .forEach(initInputFormatter)
    },
}
