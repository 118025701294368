const $ = window.$

// When modifying the constant below, make sure to update it in Kursdaten.php as well.
const STUDY_MODE_FULL_ONLINE = '1'
const HELP_TEXT_CRITERIA_CHECK = 'Neben der Zielgruppe müssen mindestens drei weitere Kriterien erfüllt werden. Sie können aus "Further criteria of the DAAD" und "Further criteria of the DAAD - Services" gewählt werden.'
const HELP_TEXT_CRITERIA_CHECK_ONLINE = 'Neben der Zielgruppe müssen hier die Vergabe von ECTS-Punkten und bei "Criteria for online programmes" die vier weitere Kriterien erfüllt werden. Bei Nicht-Erfüllung der ECTS-Punkte müssen bei "Criteria for online programmes" fünf Kriterien erfüllt werden.'
// When modifying the constant below, make sure to update it in Kurs.php as well.
const LONG_SHORT_COURSE_NAME = 'Language course/short course'

const updateStudyModes = (inputField) => {
    const studyOnline = $('select[name*="studyOnline"]')
    const courseType = $('select[name*="kursart"]')
    if (courseType.val() === LONG_SHORT_COURSE_NAME) {
        updateTextByStudyModes(inputField, studyOnline)
        inputField.on('change', function () {
            updateTextByStudyModes(inputField, studyOnline)
        })
        studyOnline.on('change', function () {
            updateTextByStudyModes(inputField, studyOnline)
        })
    }
}

const updateTextByStudyModes = (inputField, studyOnline) => {
    const criteriaCheck = $('ul[id$="criteriaCheck"]')
    const helpText = criteriaCheck.closest('.js-form-row').find('span.help-block')
    if (inputField.val() === STUDY_MODE_FULL_ONLINE && studyOnline.val() === '1') {
        helpText.text(HELP_TEXT_CRITERIA_CHECK_ONLINE)
    } else {
        helpText.text(HELP_TEXT_CRITERIA_CHECK)
    }
}

const updateFields = {
    courseIsAccredited: $('select[name*="courseIsAccredited"]'),
    studyModes: $('select[name*="studyModes"]'),
    init () {
        updateStudyModes(this.studyModes)
    },
}
export default updateFields
