import {
    append, assoc, compose, curry, findIndex, last, split, propSatisfies, when, without,
} from 'ramda'

import {
    isRadioButtonGroup, isRTEField, isLockindicator, computeFieldId, getParentForm,
    getLockIndicatorChild,
} from '@/util/sonataHelper'
import EventBus from '@/util/EventBus'

const $ = window.$


const isNotConfirmedClass = 'mdi-alert'
const isConfirmedClass = 'mdi-check'
const isNotConfirmedTitle = 'Der Inhalt dieses Feldes wurde aus einer anderen Version übernommen. Bitte überprüfen Sie diesen und kennzeichnen Sie dieses Feld als "korrekt".'
const isConfirmedTitle = 'Der Inhalt dieses Feldes wurde aus einer anderen Version übernommen. Sie haben diesen als "geprüft" gekennzeichnet.'

// computeFieldElem :: String|[String] -> jQuery Elem
const computeFieldElem = curry((basePath, pathArray) => compose(
    id => $(`#${id}`),
    computeFieldId(basePath),
)(pathArray))

// getFormField :: String|[String] -> jQuery Elem
const getFormField = curry((basePath, pathArray) => compose(
    when(
        isLockindicator,
        _ => computeFieldElem(basePath, append(last(pathArray), pathArray)),
    ),
    computeFieldElem(basePath),
)(pathArray))

/**
 * Render the icon for confirming a form field of course.
 *
 * @sig jQuery Elem -> jQuery Elem
 */
const renderIcon = ($formField) => {
    // Quotes need to be replaced, otherwise text will be striped.
    const title = isNotConfirmedTitle.replace(/"/g, '&quot;')

    const $icon = $(`
        <i
            class="mdi ${isNotConfirmedClass} lock-open js-lock-field c--error lock-type__field"
            data-toggle="tooltip"
            title="${title}"
        ></i>
    `)

    const $parent = isRTEField($formField)
        ? $formField.closest('.sonata-ba-field').find('div') // required for css to work with multiple icons
        : $formField.closest('.sonata-ba-field')

    $formField.closest('.sonata-ba-field').addClass('lock-type')
    $icon.addClass('lock-type__field')
    if (isRadioButtonGroup($formField)) {
        $icon.addClass('lock-type__field--radio')
    }

    if (isRTEField($formField)) {
        $icon.addClass('lock-type__field--rte')
    }

    return $icon.prependTo($parent)
}

/**
 * Remove icon from the DOM.
 *
 * @sig jQuery Elem -> undefined
 */
const removeIcon = ($icon) => {
    $icon.closest('.sonata-ba-field').removeClass('lock-type')
    $icon.off('click')
    $icon.detach()
}

/**
 * Confirmation of form fields of a course.
 *
 * All fields that are not yet confirmed, are stored in a hidden field as an array
 * of dotPaths (e.g. "adressdaten.department") that reference a form type element.
 *
 * If a field is confirmed, the dotPath for that element is removed from the
 * hidden field.
 */
const initConfirmFields = ($hiddenField) => {
    const mainFormName = $hiddenField.data('main-form-name')
    if ($hiddenField.val() === '') {
        return
    }
    let _currentFields = JSON.parse($hiddenField.val())

    // _allFields :: [{ $elem: jQuery elem, dotPath: String, isConfirmed: Bool }]
    const _allFields = _currentFields
        .map(dotPath => ({
            $elem: getFormField(mainFormName, split('.', dotPath)),
            dotPath,
            isConfirmed: false,
        }))

    // _fieldArray :: [{ $elem: jQuery elem, dotPath: String, isConfirmed: Bool, $icon: jQuery Elem }]
    const _fieldsArray = _allFields
        .filter(propSatisfies($elem => $elem.length > 0, '$elem'))
        .map(obj => assoc('$icon', renderIcon(obj.$elem), obj))

    // _removeFromAllFields :: String -> undefined
    const _removeFromAllFields = (dotPath) => {
        _currentFields = without([dotPath], _currentFields)
        $hiddenField.val(JSON.stringify(_currentFields))
    }

    // _addToAllFields :: String -> undefined
    const _addToAllFields = (dotPath) => {
        _currentFields = append(dotPath, _currentFields)
        $hiddenField.val(JSON.stringify(_currentFields))
    }

    const _setFieldNeedsConfirmation = ($elem) => {
        $elem.attr('data-field-to-confirm', 'true')
        EventBus.$emit('fieldNeedsConfirmation', $elem)
    }

    const _setFieldIsConfirmed = ($elem) => {
        $elem.removeAttr('data-field-to-confirm')
        EventBus.$emit('fieldIsConfirmed', $elem)
    }

    const _confirmField = ({ $elem, dotPath, $icon }) => {
        $icon.addClass(isConfirmedClass)
        $icon.removeClass(isNotConfirmedClass)
        $icon.prop('title', isConfirmedTitle)
        _removeFromAllFields(dotPath)
        _setFieldIsConfirmed($elem)
    }

    const _unconfirmField = ({ $elem, dotPath, $icon }) => {
        $icon.removeClass(isConfirmedClass)
        $icon.addClass(isNotConfirmedClass)
        $icon.prop('title', isNotConfirmedTitle)
        _addToAllFields(dotPath)
        _setFieldNeedsConfirmation($elem)
    }

    _fieldsArray.forEach(({ $elem, dotPath, isConfirmed, $icon }) => {
        _setFieldNeedsConfirmation($elem)

        /**
         * Toggle the confirmation of a course field.
         *
         * Show  checkmark if field is confirmed and alert icon, otherwise.
         */
        const toggle = () => {
            isConfirmed = !isConfirmed
            if (isConfirmed) {
                _confirmField({ $elem, dotPath, $icon })
            } else {
                _unconfirmField({ $elem, dotPath, $icon })
            }

            $icon.tooltip('hide').tooltip('fixTitle')
        }

        const confirm = () => {
            if (!isConfirmed) {
                isConfirmed = true
                _confirmField({ $elem, dotPath, $icon })
            }
        }

        $elem.on('change', confirm) // auto confirm if user interacts with form field
        $icon.on('click', toggle)
    })

    /**
     * Auto confirm all fields that actually do not exist and should not have been
     * added to this entity in the first place.
     */
    // _unfoundFields :: [{ $elem: jQuery elem, dotPath: String, isConfirmed: Bool }]
    const _unfoundFields = _allFields.filter(propSatisfies($elem => $elem.length === 0, '$elem'))
    if (_unfoundFields.length > 0) {
        // @TODO log is here for testing purposes. Remove when site goes live?
        console.error('The following fields to confirm are not found and will be auto confirmed: ', _unfoundFields)
        _unfoundFields.forEach(({ dotPath }) => _removeFromAllFields(dotPath))
    }

    /**
     * Listen to MaskType event and auto confirm all hidden fields.
     *
     * If a hidden field has a confirmation icon, there is no way for a user
     * to confirm it. This should usually be handled serverside BEFOREHAND.
     * Becuase of time reasons, this is a workaround to auto confirm all fields
     * that a user does not see.
     */
    EventBus.$on('hideMaskField', ($elem) => {
        if (isLockindicator($elem)) {
            $elem = getLockIndicatorChild($elem) // @see getFormField
        }

        const index = findIndex(propSatisfies(
            /**
             * getParentForm is a workaround for image crop or lickindicator types.
             * This usually happens when the form config is incorrect and saves
             * us from frequent errors.
             */
            $fieldToConfirm => $fieldToConfirm.is($elem) || $fieldToConfirm.is(getParentForm($elem)),
            '$elem',
        ), _fieldsArray)

        if (index !== -1) {
            const found = _fieldsArray[index]
            _confirmField(found)
            _fieldsArray.splice(index, 1)
            removeIcon(found.$icon)
        }
    })
}

export default {
    init (root = document.body) {
        const elem = root.querySelector('.js-fields-to-confirm')
        if (elem) {
            initConfirmFields($(elem))
        }
    },
}

export const needsConfirmation = $elem => $elem.attr('data-field-to-confirm') !== 'true'
