import { singleKeyStorage } from '@/util/storage'


/**
 * Make a toggle object that persists its open state (boolean) to the localStorage.
 *
 * @param key  A string specifying the key for the localStorage
 * @param toggler  An object providing the functions open() and close()
 */
const persistableStateToggler = (key, toggler) => {
    const BrowserStorage = singleKeyStorage(key)
    let isOpen = true // is Open by default

    /**
     * Set the open status and persist to browser storage
     */
    const setIsOpen = (status) => {
        isOpen = status
        BrowserStorage.set(isOpen)
    }

    const open = () => {
        toggler.open()
        setIsOpen(true)
    }

    const close = () => {
        toggler.close()
        setIsOpen(false)
    }

    /**
     * Close or open the element.
     */
    const setStatus = (newOpenStatus) => {
        if (newOpenStatus) {
            open()
        } else {
            close()
        }
    }

    const toggle = () => setStatus(!isOpen)

    const init = () => {
        isOpen = BrowserStorage.getBoolean(true)
        setStatus(isOpen)
    }

    return { init, toggle, open, close }
}

export default persistableStateToggler
