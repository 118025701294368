const $ = window.$

const $confirmModal = $(document.getElementById('js-export-calendar-modal'))
const $confirmButton = $confirmModal.find('.js-submit')
const $cancelButton = $confirmModal.find('.js-cancel')
const $confirmModalStartDate = $('#web_statistic_export_start')
const $confirmModalEndDate = $('#web_statistic_export_end')
const $confirmModalStartDatePicker = $('#dtp_web_statistic_export_start')
const $confirmModalEndDatePicker = $('#dtp_web_statistic_export_end')
const borderColorDefault = '#d2d6de'
const borderColorError = '#ff5722'

const exportWebstatistics = () => {
    if (!$('.js-export-webstatistics').length) {
        return
    }

    $cancelButton.on('click', () => {
        $confirmModal.modal('hide')
    })

    $confirmButton.on('click', () => {
        const startDateString = $confirmModalStartDate.val()
        const endDateString = $confirmModalEndDate.val()

        $confirmModalStartDate.css('border-color', borderColorDefault)
        $confirmModalEndDate.css('border-color', borderColorDefault)

        if (!isValid(startDateString, endDateString)) {
            return
        }

        let url = decodeURIComponent($confirmButton.data('url'))
        url = url.replace('##START##', formatDate(startDateString))
        url = url.replace('##END##', formatDate(endDateString))

        window.location.href = url
        $confirmModal.modal('hide')
    })

    $confirmModalStartDatePicker.on('dp.change', (e) => {
        $confirmModalEndDatePicker.data('DateTimePicker').options.minDate = e.date
        $confirmModalEndDate.val('')
    })

    $('body').on('click', '.js-export-webstatistics', (e) => {
        e.preventDefault()

        $confirmModalStartDatePicker.datetimepicker()
        $confirmModalEndDatePicker.datetimepicker()

        $confirmModalStartDate.val('')
        $confirmModalEndDate.val('')

        $confirmButton.data('url', $(e.target).attr('href'))
        $confirmModal.modal('show')
    })
}

const isValid = (startDateString, endDateString) => {
    if (!startDateString.length || !endDateString.length) {
        if (!startDateString.length) {
            $confirmModalStartDate.css('border-color', borderColorError)
        }

        if (!endDateString.length) {
            $confirmModalEndDate.css('border-color', borderColorError)
        }

        return false
    }

    return true
}

const formatDate = (date) => {
    const dateFragments = date.split('.')

    return `${dateFragments[2]}-${dateFragments[1]}-${dateFragments[0]}`
}

export default { init: exportWebstatistics }
