import { render, staticRenderFns } from "./DataTableSort.vue?vue&type=template&id=7da95907&"
import script from "./DataTableSort.vue?vue&type=script&lang=js&"
export * from "./DataTableSort.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports