import EventBus from '@/util/EventBus'

const $ = window.$


export default {
    data: {
        hiddenFields: [],
    },

    created () {
        EventBus.$emit('requestSelectedFilters')
        EventBus.$on('selectFilters', (hiddenFields) => {
            this.hiddenFields = hiddenFields
        })
    },

    methods: {
        isVisible (str) {
            return this.hiddenFields.indexOf(str) === -1
        },

        /**
         * Align the table cell to the bottom of its table row. This has to be done
         * with javascript because table row do not work well with position relative:
         * https://stackoverflow.com/a/8502084
         *
         * Solution:
         * - put positon relative on the table element
         * - calculate the offset of each table row on hover
         * - align action bar with bottom property
         */
        onMouseOver (el) {
            const $tableRow = $(el.target).closest('tr')
            const $table = $(el.target).closest('table')
            const $dataRow = $tableRow.find('.c-list-page__cell-actions-container')

            const rowTopToTableTop = $tableRow.offset().top - $table.offset().top
            const rowBottomToTableBottom =
                1 + Math.max(0, $table.height() - rowTopToTableTop - $tableRow.height())
            $dataRow.css({ bottom: `${rowBottomToTableBottom}px` })
        },
    },
}

