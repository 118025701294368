const $ = window.$

const detectBrowser = ($dateField) => {
    const ua = navigator.userAgent
    const isOpera = ua.indexOf('Opera') !== -1 || ua.indexOf('OPR') !== -1
    const isIE = (ua.indexOf('Trident/') !== -1) || (ua.indexOf('MSIE') !== -1) || (!!document.documentMode === true)

    // Hide by default
    $dateField.context.style.display = 'none'
    if (isOpera || isIE) {
        $dateField.context.style.display = 'block'
    }
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.non-supporting-browser'))
            .map(x => $(x))
            .forEach(x => detectBrowser($(x)))
    },
}
