
import persistableStateToggler from '@/util/persistableStateToggler'

const $ = window.$
const STORAGE_KEY = 'main_menu_status'

/**
 * The general toggle of the main menu to the left.
 * State of the menu is persisted and read from to localStorage.
 */
const MainMenu = (function makeMainMenu () {
    const toggleClass = 'sidebar-collapse'
    const $root = $('body')
    const $toggle = $root.find('.sidebar-toggle')

    const toggler = {
        open: () => $root.removeClass(toggleClass),
        close: () => $root.addClass(toggleClass),
    }

    const init = () => {
        if (!$toggle.length) {
            return
        }

        const MenuToggler = persistableStateToggler(STORAGE_KEY, toggler)

        MenuToggler.init()
        $toggle.off('click') // remove old handlers to avoid conflicts

        $toggle.click(MenuToggler.toggle)
    }

    return { init }
})()

export default MainMenu
