const $ = window.$

const SUBMIT = '#form_submit'
const REGISTRATION_FRONTEND = '#registration-frontend'

const FIELD_UPDATE_INSTITUTION = '.js-update-institution'
const FIELD_FILL_INSTITUTION = '.js-fill-institution'
const FIELD_COURSE_PRESENTATIONS = '#form_coursePresentations'
const FIELD_AUDIENCE = '#form_isZielgruppeHaelfteStudierende'
const FIELD_TOGGLE_REGISTRATIONSTATUS = '#registrationOnlineToggle'

const TOAST_PRESENTATION_IMPOSSIBLE = '.js-presentation-impossible-toast'
const TOAST_INSTITUTION_ALREADY_REGISTERED = '.js-institution-already-registered-toast'

let isInstitutionAlreadyRegistered = false
let currentError = window.ERROR_REQUIRED_FIELDS_MISSING || ''

/**
 * Set event listener and deactivate submit button on start
 */
const initRegistration = () => {
    if ($(REGISTRATION_FRONTEND).length) {
        initFrontend()

        $('body').tooltip({ selector: ".js-tooltip.disabled[data-toggle='tooltip']" })
    } else {
        const $registrationStatusToggle = $(FIELD_TOGGLE_REGISTRATIONSTATUS)

        $registrationStatusToggle.click(function () {
            $registrationStatusToggle.prop('disabled', true)

            const newStatus = $registrationStatusToggle.prop('checked') ? 1 : 0
            const url = decodeURIComponent($registrationStatusToggle.data('url')).replace('###STATUS###', newStatus)

            $.ajax(url)
                .done(function () {
                    $registrationStatusToggle.prop('disabled', false)
                })
                .fail(function () {
                    $registrationStatusToggle.prop('disabled', false)
                })
        })
    }
}

const initFrontend = () => {
    initSubmit()

    $(`select${FIELD_UPDATE_INSTITUTION}`).on('change', onInstitutionChange)
    $(FIELD_COURSE_PRESENTATIONS).on('change', validateAudience)
    $(FIELD_AUDIENCE).on('change', validateAudience)

    // Must be last, so submit button can be disabled based on the other actions
    $('input, textarea, select').on('change', initSubmit)
}

/**
 * Check if all required form fields are filled
 *
 * @sig requiredFieldsFilled :: * -> Boolean
 */
const requiredFieldsFilled = () => {
    const fields = $('input, textarea, select').filter('[required]')
    let emptyRequiredFields = 0

    $.each(fields, function (i, field) {
        if (field.type === 'checkbox' && field.checked) {
            return
        } else if (field.type !== 'checkbox' && field.value) {
            return
        }

        emptyRequiredFields += 1
    })

    emptyRequiredFields += requiredMultiCheckboxFieldsFilled()

    return !emptyRequiredFields
}

/**
 * Check if all required fields with multiple checkboxes are filled
 *
 * @sig requiredMultiCheckboxFieldsFilled :: * -> Boolean
 */
const requiredMultiCheckboxFieldsFilled = () => {
    const fields = $('.js-control-label-required:visible').parent()
    let emptyRequiredFields = 0

    $.each(fields, function (i, formGroup) {
        const $checkedCheckboxes = $(formGroup).find('.js-checkbox input:checked')

        if (!$checkedCheckboxes.length) {
            emptyRequiredFields += 1
        }
    })

    return emptyRequiredFields
}

/**
 * Activate or deactivate submit button
 */
const initSubmit = () => {
    const $submit = $(SUBMIT)
    const $submitParent = $submit.parent()

    $submit.prop('disabled', true)
    $submitParent.attr('title', currentError)
    $submitParent.tooltip('fixTitle')
    $submitParent.tooltip('enable')
    currentError = window.ERROR_REQUIRED_FIELDS_MISSING || ''

    // We need this because of the animation on mask types
    setTimeout(function () {
        if (requiredFieldsFilled() && audienceIsValid() && !isInstitutionAlreadyRegistered) {
            $submit.prop('disabled', false)
            $submit.parent().tooltip('disable')
        }
    }, 500)
}

const onInstitutionChange = () => {
    const $toast = $(TOAST_INSTITUTION_ALREADY_REGISTERED).find('.js-toast-hide')
    if (institutionAlreadyRegistered()) {
        $toast.removeClass('hidden')
        isInstitutionAlreadyRegistered = true
        currentError = window.ERROR_INSTITUTION_ALREADY_REGISTERED || ''
    } else {
        $toast.addClass('hidden')
        isInstitutionAlreadyRegistered = false
    }
    copyInstitutionName()
}

const copyInstitutionName = () => {
    $(`input${FIELD_FILL_INSTITUTION}`).val(optionValueToLabel($(`select${FIELD_UPDATE_INSTITUTION}`)))
}

const optionValueToLabel = $from => $from.find(`option[value=${$from.val()}]`).text()

/**
 * Check if the selected institution was already registered
 *
 * @sig institutionAlreadyRegistered :: * -> Boolean
 */
const institutionAlreadyRegistered = () => {
    const $institution = $(`select${FIELD_UPDATE_INSTITUTION}`)
    const selectedInstitution = $institution.val()
    const alreadyRegisteredInstitutions = $institution.data('checked-values-registered')

    return $.inArray(selectedInstitution, alreadyRegisteredInstitutions) >= 0
}

/**
 * Check if the selected options are a valid combination, concerning the audience.
 *
 * @sig audienceIsValid :: * -> Boolean
 */
const audienceIsValid = () => {
    const $coursePresentations = $(FIELD_COURSE_PRESENTATIONS)

    const hasLanguageAndPrepCourses = $.inArray('Sprach- und Fachkurse', $coursePresentations.val()) >= 0
    const isOverFiftyPercent = $(FIELD_AUDIENCE).val() !== '0'

    return !(hasLanguageAndPrepCourses && !isOverFiftyPercent)
}

/**
 * Show error toast if audience combination is not valid.
 */
const validateAudience = () => {
    const $toast = $(TOAST_PRESENTATION_IMPOSSIBLE).find('.js-toast')

    if (audienceIsValid()) {
        $toast.addClass('hidden')
    } else {
        $toast.removeClass('hidden')
        currentError = window.ERROR_PRESENTATION_IMPOSSIBLE || ''
    }
}

export default { init: initRegistration }
