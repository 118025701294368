import { compose, concat, difference, uniq } from 'ramda'

const $ = window.$


/**
 * Component for storing the dotPaths of hidden form elements. This is used
 * to store the paths (e.g. 'adressdate.contactTitle') of masked types.
 *
 * @sig ([String] -> *) F => { add: F, remove: F }
 */
export default (() => {
    const _$field = $('.js-masked-fields')
    if (_$field.length === 0) {
        return { add: () => {}, remove: () => {} }
    }

    // _currentFields :: [String]
    let _currentFields = []

    const _setFields = () => {
        _$field.val(JSON.stringify(_currentFields))
    }

    // add :: [String] -> *
    const add = (dotPaths) => {
        _currentFields = compose(
            uniq,
            concat(dotPaths),
        )(_currentFields)
        _setFields()
    }

    // remove :: [String] -> *
    const remove = (dotPaths) => {
        _currentFields = difference(_currentFields, dotPaths)
        _setFields()
    }

    _setFields() // initialize value

    return { add, remove }
})()
