const $ = window.$
const HIDE_COURSE_LEAVE_MESSAGE = 0
const SHOW_COURSE_LEAVE_MESSAGE = 1
const COURSE_LEAVE_MESSAGE_ID = '.js-course-leave-message'
const SHOW_INDICATOR_ID = '.js-is-show-indicator-course-leave-message'

const courseLeaveMessage = ($dateField) => {
    const messageBlock = $(COURSE_LEAVE_MESSAGE_ID)
    const updateField = () => {
        const show = (parseInt($dateField.val()) === SHOW_COURSE_LEAVE_MESSAGE)
        messageBlock.toggle(show)
    }
    $(window).on('load', updateField)
    $dateField.on('change', updateField)
}

const hideMessage = (root, $dateField) => {
    const isShowIndicator = $(SHOW_INDICATOR_ID)
    const closeMessageBlock = $dateField.find('.mdi-close')
    const updateValue = () => {
        isShowIndicator.val(HIDE_COURSE_LEAVE_MESSAGE)
    }
    closeMessageBlock.on('click', updateValue)
}

const showMessage = (root, $dateField) => {
    const isShowIndicator = $(SHOW_INDICATOR_ID)
    const updateValue = () => {
        isShowIndicator.val(SHOW_COURSE_LEAVE_MESSAGE)
    }
    $dateField.on('click', updateValue)
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll(SHOW_INDICATOR_ID))
            .map(x => $(x))
            .forEach(x => courseLeaveMessage($(x)))

        Array.from(root.querySelectorAll(COURSE_LEAVE_MESSAGE_ID))
            .map(x => $(x))
            .forEach(x => hideMessage(root, $(x)))

        Array.from(root.querySelectorAll('.js-btn-update-and-list'))
            .map(x => $(x))
            .forEach(x => showMessage(root, $(x)))
    },
}
