
import persistableStateToggler from '@/util/persistableStateToggler'

const $ = window.$
const STORAGE_KEY = 'layout_status'
const openIcon = 'mdi-arrow-right'
const closedIcon = 'mdi-arrow-left'


/**
 * The general layout component with main content and optional collapsible sidebar
 * on the right. State of sidebar is persisted and read from to localStorage.
 */
const LayoutGrid = (function makeLayoutGrid () {
    const toggleClass = 'c-layout--aside-open'
    const $root = $('.js-layout')
    const $toggle = $root.find('.js-layout-toggle')
    const $toggleIcon = $toggle.find('i.mdi')

    const toggler = {
        open () {
            $toggle.prop('title', $toggle.data('close-title'))
            $root.addClass(toggleClass)
            $toggleIcon.removeClass(closedIcon)
            $toggleIcon.addClass(openIcon)
        },
        close () {
            $toggle.prop('title', $toggle.data('open-title'))
            $root.removeClass(toggleClass)
            $toggleIcon.removeClass(openIcon)
            $toggleIcon.addClass(closedIcon)
        },
    }

    const init = () => {
        if (!$toggle.length) {
            return
        }

        const LayoutToggler = persistableStateToggler(STORAGE_KEY, toggler)

        LayoutToggler.init()

        // click on sidebar toggle, hide and fix tooltips
        $toggle.click((e) => {
            e.preventDefault()
            LayoutToggler.toggle()
            $toggle.tooltip('hide').tooltip('fixTitle')
        })
    }

    return { init }
})()

export default LayoutGrid
