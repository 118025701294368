import { anyPass, contains, filter, isEmpty, map, reject, startsWith } from 'ramda'
import { getFromFormBySonataSelector } from '@/util/sonataHelper'

const $ = window.$


const initRestrictEintragsart = ($selectField) => {
    // $target :: jQuery Elem
    const $target = getFromFormBySonataSelector($selectField, $selectField.data('sonata-selector'))
    // allowedValues :: [String]
    const allowedValues = $selectField.data('restrict-to')

    // isRestrictedValue :: String -> Boolean
    const isRestrictedValue = anyPass(map(startsWith, allowedValues))

    // restrictFrom :: [String]
    const restrictFrom = $selectField.data('restrict-from')

    // allOptions :: [jQuery Elem]
    const allOptions = Array.from($target.get(0).querySelectorAll('option'))
        .map(x => $(x))

    // notAllowedOptions :: [jQuery Elem]
    const notAllowedOptions = reject(
        $elem => isRestrictedValue($elem.html()) || isEmpty($elem.val()),
        allOptions,
    )

    // restrictedOptions :: [jQuery Elem]
    const restrictedOptions = filter(
        $elem => isRestrictedValue($elem.html()) || isEmpty($elem.val()),
        allOptions,
    )

    // restrictedFormValues :: [String]
    const restrictedFormValues = restrictedOptions.map($elem => $elem.val())

    /**
     * If $selectField updates, check if the new value of $selectField is
     * in the array of values that trigger a restriction of the fields
     * of the $target select tag.
     */
    const updateValue = () => {
        // since the DOM will update, we need to store this before
        const currentTargetValue = $target.val()

        if (contains($selectField.val(), restrictFrom)) {
            notAllowedOptions.forEach($e => $e.detach())
            if (!contains(currentTargetValue, restrictedFormValues)) {
                $target.val('').trigger('change') // reset select tag
            }
        } else {
            /**
             * $target.html('') does not work here, because IE destroys all
             * DOM elements although jQuery guarantees that it doesnt :(
             */
            allOptions.forEach($e => $e.detach())
            allOptions.forEach($e => $target.append($e))
            $target.val(currentTargetValue).trigger('change') // update because DOM has changed
        }
    }

    updateValue()
    $selectField.on('change', updateValue)

    return () => {
        $selectField.off('change', updateValue)
    }
}

/**
 *  Component for updating the eintragsart when the kursart changes.
 */
export default (() => {
    // _unmount :: [Function]
    const _unmount = []

    const init = (root = document.body) => {
        Array.from(root.querySelectorAll('.js-restrict-eintragsart'))
            .map(elem => $(elem))
            .filter($elem => !$elem.hasClass('select2-container'))
            .forEach(($elem) => {
                const destroyComponent = initRestrictEintragsart($elem)
                _unmount.push(destroyComponent)
            })
    }

    /**
     * Reinitialize the components. No need to pass `root` here, because
     * we update all currently existing components in the DOM.
     *
     * @TODO This could be improved to only reinitialize a specific select field
     * component at a later point if performance decreases.
     */
    const reinit = () => {
        _unmount.forEach(destroyComponent => destroyComponent())
        _unmount.splice(0, _unmount.length) // clear all

        init(document.body)
    }

    return { init, reinit }
})()
