import { getFromFormBySonataSelector } from '@/util/sonataHelper'
import RestrictEintragsart from './RestrictEintragsart'

const $ = window.$


/**
 * Update for GATE Members
 */
export const updateGateComponent = ({
    isGate, // Boolean specifying whether university is a gate member
    priceMap, // object of the form { gate: { *: String }, noGate: { *: String } }
    $gateCheckbox, // jquery Object
}) => {
    const $targetField = getFromFormBySonataSelector(
        $gateCheckbox, $gateCheckbox.data('entry-type-sonata-selector'),
    )
    updateIsGateLabel($gateCheckbox, isGate)
    updateIsGateEntryTypes($targetField, isGate, priceMap)

    // Consider restriction of entry type
    RestrictEintragsart.reinit()
}

/**
 * While creating a course only a label is displayed
 * instead of a checkbox for the isGate field.
 * When the checkbox is clicked, this label needs to be updated.
 */
const updateIsGateLabel = ($gateCheckbox, isGate) => {
    const $gateLabels = $gateCheckbox.parents('.isGate__label')
    const $activeLabel = $gateLabels.find('.isGate__label--active')
    const $inactiveLabel = $gateLabels.find('.isGate__label--inactive')

    if (isGate) {
        // Is GATE Germany Member
        $activeLabel.removeClass('hidden')
        $inactiveLabel.addClass('hidden')
        $gateCheckbox.val('1')
    } else {
        // No GATE Germany Member
        $activeLabel.addClass('hidden')
        $inactiveLabel.removeClass('hidden')
        $gateCheckbox.val('0')
    }
}

/**
 * When selecting a GATE Germany member entry type prices
 * need to be updated.
 */
const updateIsGateEntryTypes = ($entryTypeSelects, isGate, priceMap) => {
    $entryTypeSelects.each(function () {
        const $entryTypeSelect = $(this)

        // since the DOM will update, we need to store this before
        const currentEntryTypeValue = $entryTypeSelect.val()

        // Reset entry type field but keep no selection value
        $entryTypeSelect.find('option[value!=""]').remove()

        // Append options
        const entryTypeOptions = getEntryTypeOptions(priceMap, isGate)
        entryTypeOptions.forEach($e => $entryTypeSelect.append($e))

        // Set previous value
        $entryTypeSelect.val(currentEntryTypeValue).trigger('change') // update because DOM has changed
    })
}

const getEntryTypeOptions = (priceMap, isGate) => {
    const prices = isGate ? priceMap.gate : priceMap.noGate

    const entryTypeOptions = []
    $.each(prices, function (index, price) {
        entryTypeOptions.push(`<option value="${index}">${price}</option>`)
    })

    return entryTypeOptions
}

export { updateIsGateLabel, updateIsGateEntryTypes }
