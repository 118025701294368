import { pathOr } from 'ramda'
import { debounce } from '@/util/debounce'

const $ = window.$


const ajaxBaseUrl = process.env.AJAX_BASE_URL
const doneTypingInterval = 300 // time in ms, 0.3 seconds

/**
 * Automatically generate town name by given zip with Google Maps Geocoder.
 * Before retrieving town, check if user has finished typing.
 */
const getTownByZip = () => {
    // Determine fields where the magic should happen
    // @TODO Refactoring
    const formFields = [
        {
            zipFormField: 'input[id$=_contactPostcode_contactPostcode]',
            townFormField: 'input[id$=_contactLocation]',
        },
        {
            zipFormField: 'input[id$=_invoicePostcode_invoicePostcode]',
            townFormField: 'input[id$=_invoiceLocation]',
        },
        {
            zipFormField: 'input[id$=_kursdaten_coursePostalCode]',
            townFormField: 'input[id$=_kursdaten_courseLocationEn]',
        },
        {
            zipFormField: 'input[id$=_zusatzinformationStipendienreferat_contactPostcode]',
            townFormField: 'input[id$=_zusatzinformationStipendienreferat_contactCity]',
        },
    ]

    formFields.forEach(({ zipFormField, townFormField }) => {
        const $formFieldZip = $(zipFormField)
        const $formFieldTown = $(townFormField)

        const getTown = () => {
            const zip = $formFieldZip.val()
            if (zip.length < 3) {
                updateDOM({ town: '', $formFieldTown }) // Reset town field
                return
            }

            $.ajax({
                type: 'GET',
                url: `${ajaxBaseUrl}/location/${zip}/`,
                success (data) {
                    const town = pathOr('', ['location', 'name'], data)
                    if (town !== '') {
                        updateDOM({ town, $formFieldTown })
                    }
                },
            })
        }

        const onChange = debounce(getTown, doneTypingInterval)
        $formFieldZip.on('keydown', onChange)
        // do no react on change event to prevent overriding city if postcode
        // is copied by CopyFormFields.js
    })
}

/**
 * @sig { town: String, formFieldTown: jQuery Element } -> undefined
 */
const updateDOM = ({ town, $formFieldTown }) => {
    $formFieldTown.val(town)
}

export default { init: getTownByZip }
