import DataTableOptions from './components/DataTableOptions.vue'
import DataTable from './components/DataTable'
import { initVueComponent } from './util/vue'
import LayoutGrid from './components/jquery/LayoutGrid'
import FixedSidebar from './components/jquery/FixedSidebar'
import MainMenuToggle from './components/jquery/MainMenuToggle'
import LockType from './components/jquery/LockType'
import BatchActions from './overrides/BatchActions'
import EditErhebungsrunde from './overrides/EditErhebungsrunde'
import AdminBase from './components/jquery/AdminBase'
import RememberTabs from './overrides/RememberTabs'
import GetTownByZip from './components/jquery/GetTownByZip'
import ExportWebstatistics from './components/jquery/ExportWebstatistics'
import SaveScrollPosition from './components/jquery/SaveScrollPosition'
import Registration from './components/jquery/Registration'
import Toast from './components/jquery/Toast'
import DetectBrowser from './components/jquery/DetectBrowser'
import showInfoForFirstLogin from './overrides/showInfoForFirstLogin'


// javascript overrides
import './overrides/treeview' // include before Admin.js !
import './overrides/Admin'
import './overrides/adminLTE'
import './overrides/confirmExit'
import './overrides/prestaCropper'

const $ = window.$

// sonata styles
if (process.env.NODE_ENV === 'production') {
    require('../../../../web/bundles/sonatacore/vendor/bootstrap/dist/css/bootstrap.min.css')

    require('../../../../web/bundles/sonataadmin/vendor/admin-lte/dist/css/AdminLTE.min.css')

    require('../../../../web/bundles/sonataadmin/vendor/jqueryui/themes/base/jquery-ui.css')
    require('../../../../web/bundles/sonatacore/vendor/select2/select2.css')
    require('../../../../web/bundles/sonatacore/vendor/select2-bootstrap-css/select2-bootstrap.min.css')
    require('../../../../web/bundles/sonataadmin/vendor/x-editable/dist/bootstrap3-editable/css/bootstrap-editable.css')
    require('../../../../web/bundles/sonatacore/vendor/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css')

    require('../../../../web/bundles/sonataadmin/css/styles.css')
    require('../../../../web/bundles/sonataadmin/css/layout.css')
    require('../../../../web/bundles/sonataadmin/css/tree.css')
    require('../../../../web/bundles/sonataadmin/css/colors.css')

    require('../../../../web/bundles/sonataformatter/markitup/skins/sonata/style.css')
    require('../../../../web/bundles/sonataformatter/markitup/sets/markdown/style.css')
    require('../../../../web/bundles/sonataformatter/markitup/sets/html/style.css')
    require('../../../../web/bundles/sonataformatter/markitup/sets/textile/style.css')

    require('../css/app.scss')
} else {
    require('../css/app.scss')
}

// own styles have to be included last


$(document).ready(function () {
    DetectBrowser.init()
    AdminBase.init()
    LayoutGrid.init()
    LockType.init()
    FixedSidebar.init()
    BatchActions.init()
    EditErhebungsrunde.init()
    MainMenuToggle.init()
    RememberTabs.init()
    GetTownByZip.init()
    ExportWebstatistics.init()
    SaveScrollPosition.init()
    Registration.init()
    Toast.init()
    showInfoForFirstLogin.init()

    initVueComponent('.js-data-table-options', DataTableOptions)
    initVueComponent('.js-data-table', DataTable)

    $('.js-submit-on-change').change(function () {
        this.form.submit()
    })
})
