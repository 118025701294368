import { isNil } from 'ramda'
import { valueOfFormField } from '@/util/sonataHelper'
import { singleKeyStorage, propStorage } from '@/util/storage'
import {
    appendParamsObj, appendURLSearchParams,
    urlToURLSearchParams, stripQueryString,
} from '@/util/url'

const $ = window.$


// store export options in LocalStroage
const FILTER_STORAGE = singleKeyStorage('export-filter-selection')

// value of radio button, when user wants to select all columns
const DISABLE_COLUMN_SELECTION = '0'

// value of radio button, when user wants to select individual columns
const ENABLE_COLUMN_SELECTION = '1'

// value of radio button, when user wants to import all rows
const ENABLE_ALL_ROWS_SELECTION = '0'

// value of select field, when user wants to specify individual export
const INDIVIDUAL_EXPORT_SELECTION = 'individual'

/**
 * Wrapper function for adding a change listener to a select field that is handled
 * by select2. Returns a handle to remove the event.
 *
 * @sig jQuery Elem -> (String -> undefined) -> (() -> undefined)
 */
const select2OnChange = ($elem, cb) => {
    const handler = (e) => {
        if (!isNil(e.val)) { // e.val comes only from events triggered by select2 ... *sigh
            $elem.val(e.val)
        }

        cb(valueOfFormField($elem))
    }
    $elem.change(handler)

    return () => $elem.off('click', handler)
}

// initExportFilter :: jQuery Elem -> undefined
const initExportFilter = ($filterForm) => {
    /**
     * Store export options to local storage for each list view (key depends
     * on url).
     */
    const Storage = propStorage(FILTER_STORAGE, window.location.pathname)
    const actionUrl = $filterForm.attr('action')
    const actionBaseUrl = stripQueryString(actionUrl)
    // urlParams :: URLSearchParams
    const urlParams = urlToURLSearchParams(actionUrl)

    const $columnSelect = $filterForm.find('.js-export-column-select')
    $columnSelect.wrap('<div></div>') // required for hiding select2 field
    const $columnSelectContainer = $columnSelect.parent()

    const $columnsRadio = $filterForm.find('.js-export-column-radio')
    const $columnsCount = $columnsRadio.find('.js-count')
    const $rowsRadio = $filterForm.find('.js-export-row-radio')

    /**
     * The filter selections are loaded from local storage for each list view.
     */
    const _loadFromLocalStroage = () => {
        const selectedColumns = Storage.getProp('columns', [])
        $columnSelect.val(selectedColumns).trigger('change')

        const columnType = Storage.getProp('columnType', DISABLE_COLUMN_SELECTION)
        $columnsRadio.find(`input[value=${columnType}]`)
            .prop('checked', true)
            .trigger('change')

        const rowType = Storage.getProp('rowType', ENABLE_ALL_ROWS_SELECTION)
        $rowsRadio.find(`input[value=${rowType}]`)
            .prop('checked', true)
            .trigger('change')

        const exportType = Storage.getProp('exportType', INDIVIDUAL_EXPORT_SELECTION)
        $typeSelect.val(exportType).trigger('change')
    }

    const _showOrHideColumnSelect = () => {
        if (valueOfFormField($columnsRadio) === ENABLE_COLUMN_SELECTION) {
            $columnSelectContainer.fadeIn()
        } else {
            $columnSelectContainer.fadeOut()
        }
    }

    // _computeActionUrl :: * -> String
    const _computeActionUrl = () =>
        valueOfFormField($rowsRadio) === ENABLE_ALL_ROWS_SELECTION
            ? appendParamsObj({ format: urlParams.get('format') }, actionBaseUrl)
            : appendURLSearchParams(urlParams, actionBaseUrl)

    const _changeActionUrl = () => {
        $filterForm.attr('action', _computeActionUrl())
    }

    $columnsRadio.find('input').change(() => {
        _showOrHideColumnSelect()
        Storage.setProp('columnType', valueOfFormField($columnsRadio))
    })

    $rowsRadio.find('input').change(() => {
        _changeActionUrl()
        Storage.setProp('rowType', valueOfFormField($rowsRadio))
    })

    const $typeSelect = $filterForm.find('.js-export-type-select')
    select2OnChange($typeSelect, (selectedValue) => {
        if (selectedValue === INDIVIDUAL_EXPORT_SELECTION) {
            $columnsRadio.fadeIn()
            _showOrHideColumnSelect()
        } else {
            $columnsRadio.fadeOut()
            $columnSelectContainer.fadeOut()
        }
        Storage.setProp('exportType', selectedValue)
    })

    select2OnChange($columnSelect, (selectedValues) => {
        $columnsCount.html(selectedValues.length)
        Storage.setProp('columns', selectedValues)
    })

    const $formatSelect = $filterForm.find('.js-export-format-select')
    select2OnChange($formatSelect, (value) => {
        urlParams.set('format', value)
        _changeActionUrl()
    })

    _loadFromLocalStroage()
    _changeActionUrl()
}

export default {
    init () {
        const $filterForm = $('.js-export-filter-form')
        if ($filterForm.length) {
            initExportFilter($filterForm)
        }
    },
}
