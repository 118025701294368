import { getFromFormBySonataSelector } from '@/util/sonataHelper'

const $ = window.$


const initUpdateDate = (root, $radioWrapper) => {
    const newDate = $radioWrapper.data('update-date')
    const $targetDateField =
        getFromFormBySonataSelector($radioWrapper, $radioWrapper.data('update-field'))
    const $radioField = $radioWrapper.find('.radio input[value=1]')

    const updateValue = () => {
        $targetDateField.val(newDate)
    }

    $radioField.on('click', updateValue)
}

/**
 *  Component for updating a date input if radio field changes.
 */
export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-update-date-by-radio'))
            .map(x => $(x))
            .forEach(x => initUpdateDate(root, $(x)))
    },
}
