import { onConfirm } from '@/util/confirm'

const $ = window.$
const submitButtonSelector = '#submit_erhebungsrunde'

/**
 * Component for handling actions while adding/editing Erhebungsrunde
 *
 * - confirmation on submit
 */
const initEditErhebungsrunde = (root = document.body) => {
    if (!$(submitButtonSelector).length) {
        return
    }

    $(root).on('click', submitButtonSelector, function (e) {
        e.preventDefault()

        const $submitButton = $(this)
        const confirmOptions = {
            title: '',
            msg: 'Sind Sie sicher, dass Sie eine neue Erhebungsrunde anlegen möchten?',
            submit: 'Neue Erhebungsrunde anlegen',
        }

        onConfirm(confirmOptions, () => {
            $submitButton.parents('form').submit()
        })
    })
}

const EditErhebungsrunde = (function initEditErhebungsrundeField () {
    return { init: initEditErhebungsrunde }
})()

export default EditErhebungsrunde
