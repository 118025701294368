import { onConfirm } from '@/util/confirm'

const $ = window.$
const batchAllElements = '#batch_all_elements'
const $batchForm = $('#batch_form')

/**
 * Component for handling actions on multiple entities (e.g. delete many courses,
 * or deactivate multiple users).
 *
 * - button for checking all fields
 * - when checked: button to check all filtered entities
 * - clicking action will submit a form with the selected action (and csrf token)
 */
const initBatchActions = (root = document.body) => {
    // Toggle individual checkboxes when the batch checkbox is changed
    $(root).on('ifChanged change', '#list_batch_checkbox', function () {
        const checked = this.checked
        const checkboxes = $(this)
            .closest('table')
            .find('td.sonata-ba-list-field-batch input[type="checkbox"], div.sonata-ba-list-field-batch input[type="checkbox"]')

        if (window.SONATA_CONFIG.USE_ICHECK) {
            checkboxes.iCheck($(this).is(':checked') ? 'check' : 'uncheck')
        } else {
            checkboxes.prop('checked', checked)
        }

        checkboxes.each(function () {
            $(this).parents('tr').toggleClass('sonata-ba-list-row-selected', checked)
        })

        // Toggle all entities hint
        $(root).find(batchAllElements).toggleClass('hidden')
        deactivateBatchAllElements()
    })

    // Add a CSS class to rows when they are selected
    $(root)
        .on('ifChanged change', 'td.sonata-ba-list-field-batch input[type="checkbox"], div.sonata-ba-list-field-batch input[type="checkbox"]', function () {
            $(this)
                .closest('tr, div.sonata-ba-list-field-batch')
                .toggleClass('sonata-ba-list-row-selected', $(this).is(':checked'))
        })
        .trigger('ifChanged')

    $(root).on('click', '.c-list-page__record-check-toggle button', function () {
        if ($('.sonata-ba-list-row-selected').length) {
            // Hide hint when at least one checkbox is checked
            $('#batch_actions li').removeClass('hidden')
            $('.no-selection-message').addClass('hidden')
        } else {
            // Show hint when no checkbox is checked
            $('#batch_actions li').addClass('hidden')
            $('.no-selection-message').removeClass('hidden')
        }
    })

    // Select all entities
    const $selectedAction = $('#selected_batch_action')
    $(root).on('click', '#batch_actions a', function (e) {
        e.preventDefault()
        const $batchActionLink = $(this)
        const $assignActionField = $('.js-confirm-modal-assign')
        const isAssignAction = $batchActionLink.hasClass('js-assign-courses')

        $assignActionField.hide()
        if (isAssignAction) {
            $('#js-confirm-modal .js-submit').attr('disabled', true)
            $assignActionField.show()
            loadAssignUserList($assignActionField)

            const $select = $assignActionField.find('select')
            $select.on('change', () => {
                $('#js-confirm-modal .js-submit').attr('disabled', !$select.val())
            })
        }

        const confirmOptions = { title: '', msg: 'Diese Aktion wirklich für die ausgewählten Daten ausführen?' }
        const addPropToOptions = (propName) => {
            if ($batchActionLink.attr(`data-${propName}`)) {
                confirmOptions[propName] = $batchActionLink.data(propName)
            }
        }

        ['msg', 'submit', 'cancel'].forEach(addPropToOptions)

        onConfirm(confirmOptions, () => {
            $selectedAction.val($batchActionLink.attr('href'))

            if (isAssignAction) {
                $('.js-assign-user').val($assignActionField.find('select').val())
            }

            $batchForm.submit()
        })
    })

    // Select all list entries for batch actions
    $(root).on('click', `${batchAllElements} a`, function (e) {
        e.preventDefault()

        if ($(batchAllElements).data('state') === 'inactive') {
            activateBatchAllElements()
        } else {
            deactivateBatchAllElements()

            // Unselect batch action checkbox
            $('#list_batch_checkbox').prop('checked', false).trigger('change')
        }
    })
}

const activateBatchAllElements = () => {
    const batchFormAction = $batchForm.attr('action')

    $batchForm.attr('action', batchFormAction + '&all_elements=1')
    $(batchAllElements).data('state', 'active')

    $(`${batchAllElements} .inactive-text`).addClass('hidden')
    $(`${batchAllElements} .active-text`).removeClass('hidden')
}

const deactivateBatchAllElements = () => {
    const batchFormAction = $batchForm.attr('action')

    $batchForm.attr('action', batchFormAction.replace('&all_elements=1', ''))
    $(batchAllElements).data('state', 'inactive')

    $(`${batchAllElements} .active-text`).addClass('hidden')
    $(`${batchAllElements} .inactive-text`).removeClass('hidden')
}

const loadAssignUserList = ($wrapper) => {
    const $select = $wrapper.find('select')

    $.ajax({
        type: 'GET',
        url: `/app/ajax/${$select.data('application')}/user/course-assignment-list/`,
        success (userList) {
            userList.forEach(user => {
                $select.append(`<option value="${user.value}">${user.label}</option>`)
            })
        },
    })
}

const BatchActions = (function initBatchActionsField () {
    return { init: initBatchActions }
})()

export default BatchActions
