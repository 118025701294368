import { contains } from 'ramda'
import { getFromFormBySonataSelector } from '@/util/sonataHelper'

const $ = window.$
const NO_UNIVERSITY = ''
const STATE_UNIVERSITY = '0'


const initUpdateUpload = (root, $selectField) => {
    const targetUploadFields = []
    const targetRequiredFields = []
    const checkedMapGate = $selectField.data('checked-values')
    const $institutionField = $selectField
    const $targetUploadField = getFromFormBySonataSelector($selectField, $selectField.data('sonata-selector-for-upload'))
    const $selectorForTypeUniversity = getFromFormBySonataSelector($selectField, $selectField.data('sonata-selector-for-institution-type'))
    const $targetRequiredField = $targetUploadField.find('input')
    targetUploadFields.push($targetUploadField)
    targetRequiredFields.push($targetRequiredField)
    if ($selectField.data('sonata-selector-for-upload-replacement')) {
        const $targetUploadReplacementField = getFromFormBySonataSelector($selectField, $selectField.data('sonata-selector-for-upload-replacement'))
        const $targetRequiredReplacementField = $targetUploadReplacementField.find('input')
        targetUploadFields.push($targetUploadReplacementField)
        targetRequiredFields.push($targetRequiredReplacementField)
    }
    const updateValue = () => {
        let checkedMapHasNotify
        if ($selectField.data('checked-values-for-upload-edit')) {
            // The fields with upload should always be displayed in backend
            checkedMapHasNotify = !contains($selectField.val(), $selectField.data('checked-values-for-upload-edit'))
        } else {
            checkedMapHasNotify = contains($selectField.val(), $selectField.data('checked-values-for-upload'))
        }
        for (const field of targetUploadFields) {
            if (!field.hasClass('js-university-has-upload')) {
                return
            }
        }
        const alreadyUploaded = (
            NO_UNIVERSITY === $selectorForTypeUniversity.val() ||
            STATE_UNIVERSITY === $selectorForTypeUniversity.val() ||
            contains($selectField.val(), checkedMapGate) ||
            checkedMapHasNotify
        )

        if (alreadyUploaded || $institutionField.val() === '') {
            targetRequiredFields.forEach(field => field.prop('required', false))
            targetUploadFields.forEach(field => field.fadeOut(400, function () {
                field.prop('hidden', true)
            }))
        } else {
            targetRequiredFields.forEach(field => field.prop('required', true))
            targetUploadFields.forEach(field => field.fadeIn(400, function () {
                field.prop('hidden', false)
            }))
        }
    }

    updateValue()
    $selectField.on('change', updateValue)
    $selectorForTypeUniversity.on('change', updateValue)
}

const hideLockindicatorBtn = ($dateField) => {
    if (undefined !== $dateField.data('lockindicator-upload-button')) {
        const lockField = $dateField.parent().parent().children().first()
        if (lockField.hasClass('mdi-lock')) {
            $dateField.parent().children().find('button.btn-sm').remove()
        }
    }
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-update-upload'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => initUpdateUpload(root, $(x)))
        Array.from(root.querySelectorAll('.c-form--lockindicator'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => hideLockindicatorBtn($(x)))
    },
}
