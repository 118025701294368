import { both, compose, findIndex, filter, merge, union } from 'ramda'

import EventBus from '@/util/EventBus'
import {
    belongsToSelect2, stringValueOfFormField as formValue, needsClientValidation,
    isVichFiledWithUpload, setFormFieldError, setFormFieldCorrect,
    setFormFieldNonEmptyError, isImageCropHelperField,
} from '@/util/sonataHelper'
import { onChange } from '@/util/onChange'
import { needsConfirmation } from './FieldsToConfirm'
import 'array-from-polyfill'

const $ = window.$


// errorMessage :: Number -> String
const errorMessage = count => `${count} ${count === 1 ? 'Feld muss' : 'Felder müssen'} in diesem Bereich noch geprüft werden`

// tabErrorCountMessage :: Number -> String
const tabErrorCountMessage = count => count === 0 ? '' : (
    count > 1
        ? `Bitte vervollständigen Sie die ${count} gekennzeichneten Felder.`
        : 'Bitte vervollständigen Sie das gekennzeichnete Feld.'
)

// tabConfirmationCountMessage :: Number -> String
const tabConfirmationCountMessage = count => count > 0
    ? `Bei ${count} ${count > 1 ? 'Feldern' : 'Feld'} mit einem orangen Dreieck <i class="mdi mdi-alert c--error"></i> wurden Daten aus einer früheren Version dieses Kurses übertragen, welche bitte von Ihnen geprüft werden müssen. Durck Klick auf das Dreieck bestätigen Sie die Inhalte und die Daten werden in diese Version übernommen.`
    : ''

/**
 * Render the alert message for a form tab. Returns a function for updating the
 * alert.
 *
 * Note that we distinguish between two kinds of error:
 * - form errors: form fields that are required but empty
 * - confirmation fields: form fields that need to be confirmed by the use
 *   because the value was copied from another entity
 *
 * @sig jQuery Elem -> (Obj -> undefined)
 */
const renderTabMessage = ($tabRoot) => {
    const $tabMessage = $(`
        <div class="alert alert-info alert-small u-mt-0">
            <span class="count"></span>
            <span class="confirmation"></span>
        </div>
    `)
    const $tabCounter = $tabMessage.find('span.count')
    const $tabConfirmationCounter = $tabMessage.find('span.confirmation')

    // render to DOM
    $tabRoot.prepend($tabMessage)

    // :: { numErrors: Number, numConfirmationErrors: Number } -> undefined
    return ({ numErrors, numConfirmationErrors }) => {
        if (numErrors + numConfirmationErrors) {
            $tabCounter.html(tabErrorCountMessage(numErrors))
            $tabConfirmationCounter.html(tabConfirmationCountMessage(numConfirmationErrors))
            $tabMessage.fadeIn()
        } else {
            $tabMessage.fadeOut()
        }
    }
}

/**
 * Render error and success message of a form tab and return a function
 * for updating the DOM. If there are no errros, a success message is shown.
 *
 * @sig (jquery elem, Number) -> (Obj -> undefined)
 */
const renderDOM = ($navTab, numErrors) => {
    const $successBatch = $navTab.find('.js-success-batch')
    const $errorBatch = $navTab.find('.js-error-batch')
    const updateTabMessage = renderTabMessage($($navTab.attr('href')))

    // internal state:
    let currentNumErrors = numErrors
    let currentNumToConfirm = 0

    // numTotals :: * -> Number
    const numTotals = () => currentNumErrors + currentNumToConfirm

    const showError = ({ hasServerError }) => {
        if (hasServerError) {
            $errorBatch.addClass('badge-error')
            $errorBatch.removeClass('badge-info')
        } else {
            $errorBatch.addClass('badge-info')
            $errorBatch.removeClass('badge-error')
        }

        $successBatch.addClass('hidden')
        $errorBatch.attr('title', errorMessage(numTotals()))
        $errorBatch.html(numTotals())
        $errorBatch.removeClass('hidden')
    }

    const showSuccess = () => {
        $successBatch.removeClass('hidden')
        $errorBatch.addClass('hidden')
    }

    // updateDOM :: Obj -> undefined
    const updateDOM = ({ increment = 0, incConfirmation = 0, hasServerError }) => {
        currentNumErrors = Math.max(0, currentNumErrors + increment)
        currentNumToConfirm = Math.max(0, currentNumToConfirm + incConfirmation)

        updateTabMessage({
            numErrors: currentNumErrors,
            numConfirmationErrors: currentNumToConfirm,
        })

        if (numTotals()) {
            showError({ hasServerError })
        } else {
            showSuccess()
        }
    }

    // render the dom
    updateDOM({ increment: 0 })

    return updateDOM
}

// hasServerError :: Dom Elem -> Boolean
const hasServerError = (elem) =>
    $(elem).closest('.form-group').hasClass('has-error')

// selectFormFields :: Dom Elem -> [jQuery Elem]
const selectFormFields = (root) => union(
    Array.from(root.querySelectorAll('input,select,textarea'))
        .map(x => $(x))
        .filter($x =>
            !belongsToSelect2($x) &&
            $x.closest('.js-checkbox-group').length === 0 &&
            !isImageCropHelperField($x),
        ),
    Array.from(root.querySelectorAll('.js-checkbox-group'))
        .map(x => $(x)),
)

// hasNonEmptyServerError :: jQuery Elem -> Bool
const hasNonEmptyServerError = $x => {
    const id = $x.attr('id')
    return hasServerError($x) && (!needsClientValidation($x) || formValue($x) !== '' || ((id.endsWith('academyId') || id.endsWith('kursart')) && formValue($x) === '')) // Show error for academyId and kursart if they are empty
}

// selectRequiredFormFields :: Dom Elem -> [jQuery Elem]
const selectRequiredFormFields = (root) => selectFormFields(root)
    .filter($x => !hasNonEmptyServerError($x) && !isVichFiledWithUpload($x))

// markFormFieldAsError :: jQuery Elem -> undefined
const markFormFieldAsError = setFormFieldError

// markFormFieldAsCorrect :: jQuery Elem -> undefined
const markFormFieldAsCorrect = ($elem) => {
    if (needsConfirmation($elem) && !(needsClientValidation($elem) && formValue($elem) === '')) {
        setFormFieldCorrect($elem)
    }
}

/**
 * A tab for managing a form tab and displaying number of form errors in the
 * labels.
 *
 * Each tab of the form should show the number of errors:
 * - required fields that are empty -> will be updated once user enters something
 * - TODO: copied fields that have not yet been confirmed
 * - server errors (e.g. "That username is already taken") -> Will not be updated
 *
 * IMPORTANT: Form fields can be added dynamically (via CollectionType) and
 * required fields can be unrequired and vice versa (e.g. via MaskType).
 *
 * @sig Dom elem -> Obj
 */
const NavTab = (navTab) => {
    const _formRoot = document.querySelector(navTab.getAttribute('href'))
    const _$requiredFields = selectRequiredFormFields(_formRoot)

    // _numServerErrors:: * -> Number
    const _numServerErrors = () => selectFormFields(_formRoot)
        .filter(hasNonEmptyServerError)
        .length

    const _fieldsWithClientErrors = () => filter(
        both(needsClientValidation, $elem => formValue($elem) === ''),
        _$requiredFields,
    )

    // _numClientErrors:: * -> Number
    const _numClientErrors = () => _fieldsWithClientErrors().length

    const _numErrors = () => _numServerErrors() + _numClientErrors()

    // _updateDOM :: Obj -> *
    const _updateDOM = compose(
        renderDOM($(navTab), _numErrors()),
        obj => merge(obj, { hasServerError: _numServerErrors() > 0 }),
    )

    const _changeHandler = ({ oldValue, newValue, $elem }) => {
        if (!needsClientValidation($elem)) {
            return
        }

        if (newValue === '') {
            markFormFieldAsError($elem)
            _updateDOM({ increment: 1 })
        } else if (oldValue === '') {
            markFormFieldAsCorrect($elem)
            _updateDOM({ increment: -1 })
        }
    }

    // _isFormValueEmpty :: jQuery Elem -> Boolean
    const _isFormValueEmpty = $elem =>
        formValue($elem) === '' && !isVichFiledWithUpload($elem)

    /**
     * Activate an element. This may happen if a form field is revealed by
     * the mask type.
     *
     * @sig jQuery Elem -> undefined
     */
    const activate = ($elem) => {
        if (needsClientValidation($elem) && _isFormValueEmpty($elem)) {
            markFormFieldAsError($elem)
            _updateDOM({ increment: 1 })
        }
    }

    // deactivate :: jQuery Elem -> undeifned
    const deactivate = ($elem) => {
        if (!needsClientValidation($elem) && _isFormValueEmpty($elem)) {
            markFormFieldAsCorrect($elem)
            _updateDOM({ increment: -1 })
        }
    }

    /**
     * Add a new form element to this form tab. This may happen if a form element
     * is dynamically added via the collection type.
     */
    const add = ($elem) => {
        activate($elem)
        onChange(formValue, $elem, _changeHandler)
        _$requiredFields.push($elem)
    }

    /**
     * Remove a form element and update counter if necessary.
     */
    const remove = ($elem) => {
        const index = findIndex($field => $field.is($elem), _$requiredFields)
        if (index !== -1) {
            if (needsClientValidation($elem) && _isFormValueEmpty($elem)) {
                _updateDOM({ increment: -1 })
            }

            _$requiredFields.splice(index, 1)
        }
    }

    const incrementConfirmation = ($elem) => {
        markFormFieldAsError($elem)
        _updateDOM({ incConfirmation: 1 })
    }

    const decrementConfirmation = ($elem) => {
        markFormFieldAsCorrect($elem)
        _updateDOM({ incConfirmation: -1 })
    }

    _$requiredFields.forEach($elem => onChange(formValue, $elem, _changeHandler))
    _fieldsWithClientErrors().forEach($elem => markFormFieldAsError($elem))
    _updateDOM({})

    /**
     * Mark all form fields in red that have a server error. This has to be
     * done only once, since server error markers are not dynamically updated
     * (for now).
     */
    selectFormFields(_formRoot)
        .filter(hasNonEmptyServerError)
        .forEach(setFormFieldNonEmptyError)

    return {
        // contains :: Dom Elem -> Boolean
        contains: elem => $.contains(_formRoot, elem),
        add,
        remove,
        activate,
        deactivate,
        incrementConfirmation,
        decrementConfirmation,
    }
}

export default (function () {
    const NavTabs = Array.from(document.querySelectorAll('.nav-tabs li a'))
        .map(NavTab)

    // navTabOnEvent :: (String, ((NavTab, jQuery Elem) -> *)) -> undefined
    const navTabOnEvent = (eventName, callback) => {
        EventBus.$on(eventName, ($formField) => {
            NavTabs.filter(navTab => navTab.contains($formField.get(0)))
                .forEach(navTab => callback(navTab, $formField))
        })
    }

    // react to fields which are unrequired by mask type
    navTabOnEvent('setUnrequiredMaskField', (navTab, $formField) => {
        navTab.deactivate($formField)
    })
    // react to fields which are required by mask type
    navTabOnEvent('setRequiredMaskField', (navTab, $formField) => {
        navTab.activate($formField)
    })
    // react to fields that are confirmed by user
    navTabOnEvent('fieldIsConfirmed', (navTab, $formField) => {
        navTab.decrementConfirmation($formField)
    })
    // react to fields that are unconfirmed by user
    navTabOnEvent('fieldNeedsConfirmation', (navTab, $formField) => {
        navTab.incrementConfirmation($formField)
    })

    // react to fields which are removed from the dom by CollectionType
    EventBus.$on('removeCollectionTypeElement', ($formField) => {
        const requiredFields = selectRequiredFormFields($formField.get(0))
        NavTabs.forEach(navTab => requiredFields.forEach(navTab.remove))
    })

    const init = (root = document.body) => {
        NavTabs.filter(navTab => navTab.contains(root)).forEach((navTab) => {
            selectRequiredFormFields(root).forEach(($elem) => {
                navTab.add($elem)
            })
        })
    }

    return { init }
})()

