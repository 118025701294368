<template>
    <div>
        <h5 class="data-table__headline">Zeilen pro Seite</h5>
        <div class="radio " v-for="(reloadPath, pageNumber) in items">
            <label class="data-table__item" :for="pageNumber">
                <input
                    type="radio"
                    :id="pageNumber"
                    :value="pageNumber"
                    v-model="currentNumPerPage"
                    @change="onChange(reloadPath)"
                >
                {{ pageNumber }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            items: {},
            currentNumPerPage: 0,
        }
    },

    created () {
        this.items = window.initialData.sorting.items
        this.currentNumPerPage = window.initialData.sorting.currentNumPerPage
    },

    methods: {
        // reload page with path proviced by sonata
        onChange (reloadPath) {
            window.location.assign(reloadPath)
        },
    },
}
</script>
