import { getFromFormBySonataSelector, getContainerBySonataSelector } from '@/util/sonataHelper'

const $ = window.$

const setAcademyStatus = ($selectField) => {
    const statusMap = $selectField.data('academy-status')
    const $targetContainer =
        getContainerBySonataSelector($selectField, $selectField.data('academy-status-sonata-selector'))
    const $targetSelectField =
        getFromFormBySonataSelector($selectField, $selectField.data('academy-status-sonata-selector'))[0]

    const updateValue = () => {
        const $targetField = $targetContainer.find('.select2-chosen')[0]

        if (!$targetField) {
            return
        }

        const $selectOptions = $targetSelectField.options
        const status = statusMap[$selectField.val()]
        const option = [...$selectOptions].filter(opt => status === parseInt(opt.value))

        if (option[0]) {
            $targetField.innerHTML = option[0].innerHTML
        } else {
            $targetField.innerHTML = 'No selection'
        }
    }
    updateValue()
    $selectField.on('change', updateValue)
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-update-academy-status'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => setAcademyStatus($(x)))
    },
}
