import Vue from 'vue'

/**
 * Use the vue instance as an event bus. If the application grows we might
 * consider replacing it by vuex.
 *
 * See here for a tutorial: https://alligator.io/vuejs/global-event-bus/
 */
const EventBus = new Vue()

export default EventBus
