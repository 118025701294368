const $ = window.$

const HelpText = ($dateField) => {
    const updateField = () => {
        const helpBlock = $dateField.parents('.form-group').first().find('.help-block')
        const show = ($dateField.val() === 'course-date')
        helpBlock.toggle(show)
    }
    $(window).on('load', updateField)
    $dateField.on('change', updateField)
}

export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-update-help'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => HelpText($(x)))
    },
}
