import { isWebUri } from 'valid-url'

const $ = window.$


/**
 * Render an anchor tag to a input[type="url"] field. Returns a function to update
 * the href attribute.
 *
 * @sig jQuery Elem -> ({ url: String } -> undefined)
 */
const renderDOM = ($inputField) => {
    const $inputLink = $(`<a
            class="small-text u-hp"
            href="#"
            target="_blank"
            style="position: absolute; bottom: 6px; right: 0;"
        >
            <span class="u-separator--help">Url öffnen</span>
        </a>
    `)

    $inputField.css({ 'padding-right': '5em' })
    $inputField.wrap('<div style="position: relative;"></div>')
    $inputLink.insertAfter($inputField)

    const updateDOM = ({ url }) => {
        if (isWebUri(url)) {
            $inputLink.attr('href', url)
            $inputLink.fadeIn()
        } else {
            $inputLink.attr('href', '')
            $inputLink.fadeOut()
        }
    }

    updateDOM({ url: $inputField.val() })

    return updateDOM
}

/**
 * Url type input fields need to have a link to open the url.
 */
const initUrlType = ($elem) => {
    const updateDOM = renderDOM($elem)
    $elem.on('input', () => updateDOM({ url: $elem.val() }))
}

export default {
    init: (root = document.body) => {
        Array.from(root.querySelectorAll('input[type="url"]'))
            .forEach(el => initUrlType($(el)))
    },
}
