import {
    compose, concat, contains, curry, drop, dropWhile, identity,
    ifElse, is, join, map, mapObjIndexed, takeWhile, unless, useWith, values,
} from 'ramda'
import URLSearchParams from 'url-search-params'


/**
 * Encode the value to be used in a uri
 *
 * @sig String -> String
 */
export const urify = value => encodeURIComponent(value).replace(/%2F/g, '/')

/**
 * Makes a query string as required for a GET request.
 *
 * @sig (String, String | [String]) -> String
 */
export const querify = curry((paramName, param) => ifElse(
    is(Array),
    compose(map(querify(`${paramName}[]`)), join('&')),
    p => `${urify(paramName)}=${urify(p)}`,
)(param))

// objToQueryString :: { k: String | [String] } -> String
export const objToQueryString = compose(
    join('&'),
    values,
    mapObjIndexed((value, key) => querify(key, value)),
)

// appendQueryString :: String -> String -> String
export const appendQueryString = curry((getString, url) => compose(
    concat(url),
    unless(_ => contains('?', url), concat('?')),
)(getString))

// appendGetParams :: { k: String | [String] } -> String -> String
export const appendParamsObj = useWith(
    appendQueryString,
    [objToQueryString, identity],
)

// appendURLSearchParams :: URLSearchParams -> String -> String
export const appendURLSearchParams = curry((searchParams, url) =>
    appendQueryString(searchParams.toString(), url),
)

// urlToQueryString :: String -> String
export const urlToQueryString = compose(
    drop(1),
    dropWhile(x => x !== '?'),
)

// urlToSearchParams :: String -> URLSearchParams
export const urlToURLSearchParams = compose(
    queryString => new URLSearchParams(queryString),
    urlToQueryString,
)

// stripQueryString :: String -> String
export const stripQueryString = takeWhile(x => x !== '?')
