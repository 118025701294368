import { getContainerBySonataSelector } from '@/util/sonataHelper'
import { onConfirm, onConfirmOrCancel, getConfirmOptions } from '@/util/confirm'
import { lockField } from '../jquery/LockType'

const $ = window.$


// initTriggerLockAction :: jQuery -> undefined
const initTriggerLockAction = ($elem) => {
    if ($elem.prop('tagName') !== 'INPUT' || $elem.prop('type') !== 'checkbox') {
        console.error('The class "js-lock-if-checked" can only be attached to checkboxes.', $elem)
        return
    }

    // fieldsToLock :: [jQuery]
    const fieldsToLock = $elem.data('lock-map')
        .map(getContainerBySonataSelector($elem))
    // confirmOption = { msg: String, title?: String, submit?: String }
    const confirmOptions = getConfirmOptions($elem)

    $elem.on('change', () => {
        if ($elem.prop('checked')) {
            onConfirm(confirmOptions, () => fieldsToLock.forEach(lockField))
        }
    })
}

// initTriggerLockActionForChoiceType :: jQuery -> undefined
const initTriggerLockActionForChoiceType = ($elem) => {
    if ($elem.prop('tagName') !== 'SELECT' || $elem.prop('type') !== 'select-one') {
        console.error('The class "js-lock-if-true-in-choice" can only be attached to selectes.', $elem)
        return
    }

    // fieldsToLock :: [jQuery]
    const fieldsToLock = $elem.data('lock-map')
        .map(getContainerBySonataSelector($elem))
    // confirmOption = { msg: String, title?: String, submit?: String }
    const confirmOptions = getConfirmOptions($elem)

    $elem.on('change', () => {
        if ($elem.prop('value') === '1') {
            onConfirmOrCancel(confirmOptions, () => fieldsToLock.forEach(lockField), () => $elem.prop('value', '').trigger('change'))
        }
    })
}

/**
 * When user clicks a checkbox with class js-lock-if-checked then all fields
 * in the data-lock-map attribute will be locked, when user confirms the
 * modal dialog.
 *
 * Note that fields in data-lock-map contain relative paths to the fields.
 *
 * @see https://extranet.snoopmedia.com/issuetracker/view.php?id=34708
 */
export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-lock-if-checked'))
            .map(x => $(x))
            .forEach(initTriggerLockAction)
        Array.from(root.querySelectorAll('select.js-lock-if-true-in-choice'))
            .map(x => $(x))
            .forEach(initTriggerLockActionForChoiceType)
    },
}
