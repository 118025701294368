import { contains } from 'ramda'
import { getFromFormBySonataSelector } from '@/util/sonataHelper'
import { updateGateComponent } from './UpdateGate'

const $ = window.$


const initUpdateCheckbox = (root, $selectField) => {
    const checkedMap = $selectField.data('checked-values')
    const priceMap = $selectField.data('price-map')
    const $targetCheckbox =
        getFromFormBySonataSelector($selectField, $selectField.data('sonata-selector'))

    const updateValue = () => {
        const isChecked = contains($selectField.val(), checkedMap)
        $targetCheckbox.prop('checked', isChecked)

        if ($targetCheckbox.hasClass('js-university-to-gate-member')) {
            updateGateComponent({
                isGate: isChecked,
                priceMap,
                $gateCheckbox: $targetCheckbox,
            })
        }
    }

    updateValue()
    $selectField.on('change', updateValue)
}

/**
 *  Component for updating a checkbox if select field changes.
 */
export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('.js-update-checkbox'))
            .map(x => $(x))
            .filter($x => !$x.hasClass('select2-container'))
            .forEach(x => initUpdateCheckbox(root, $(x)))
    },
}
