import {
    getFromFormBySonataSelector,
    setValueOfFormField,
    valueOfFormField,
} from '@/util/sonataHelper'

const $ = window.$


const autoFillCoordinatorRole = ($elem) => {
    const $targetField = getFromFormBySonataSelector($elem, $elem.data('target-name'))
    const trueValue = $elem.data('target-true-value')
    const falseValue = $elem.data('target-false-value')
    // idsWithCoordinator :: [String]
    const idsWithCoordinator = $elem.data('academy-with-coordinator').map(x => `${x}`)

    $elem.on('change', () => {
        const currentValue = valueOfFormField($elem)
        setValueOfFormField(
            $targetField,
            idsWithCoordinator.includes(currentValue) ? falseValue : trueValue,
        )
    })
}

/**
 * Set the values of a coordinator field to true if user selects an academy
 * that does not have a coordinator yet.
 *
 * @example
 *
 * <select
 *     class="js-check-coordinator"
 *     data-target-name="coordinator"
 *     data-target-false-value="0"
 *     data-target-true-value="1"
 *     data-academy-with-coordinator="[51, 54]"
 * >
 *     <option value="51">51</option>
 *     <option value="52">52</option>
 *     <option value="53">53</option>
 *     <option value="54">54</option>
 * </select>
 * <select
 *     id="sonta-hash-coordinator"
 * >
 *     <option value="0">False</option>
 *     <option value="1">True</option>
 * </select>
 */
export default {
    init: root => Array.from(root.querySelectorAll('.js-check-coordinator:not(.select2-container)'))
        .map(x => $(x))
        .forEach(autoFillCoordinatorRole),
}

